import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-medium-image-zoom/dist/styles.css';
import ModalImage from "react-modal-image";
import Modal from 'react-bootstrap/Modal';
import { Tab, Tabs } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import moment from 'moment';
import { getAuth, database, secondDatabase } from '../firebaseConfig';
import axios from 'axios';
import { Loader } from '../components/loader';
import { UserAuth } from '../context/AuthContext';
import AWS from 'aws-sdk';
import CryptoJS from 'crypto-js';

export const TotalTestList = () => {
    const location = useLocation();
    const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const encryptedQuery = queryParams.get('query');

    const auth = getAuth();
    const [userDetails, setUserDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [candidateTestList, setCandidateTestList] = useState([]);
    const [userParam, setUserParam] = useState(null);

    const { user, userInfoLoaded, currentUserVal } = UserAuth();

    // For Video Fullscreen Model 
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // For Nav & Tabs For Details
    const [key, setKey] = useState('home');

    const detail = location.state;
    console.log("User Details");
    console.log(detail);

  // Decrypt the query string
  const bytes = CryptoJS.AES.decrypt(encryptedQuery, 'secret_key');
  const decryptedQuery = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));


    // Fetch Test Questions and Reason
    const fetchTestInfo = async (candidateEmail) => {
        console.log(candidateEmail)
        console.log("candidateEmail")
        setIsLoading(true);
        try {
            const refreshedToken = await currentUserVal?.getIdToken(true);
            var collectdata = {
                "candidateEmail": candidateEmail
            };
            if (user?.userType == "MGR" || user?.userType == "SMGR") {
                collectdata.userType = user?.userType;
                collectdata.managerEmail = user?.email;
            }
            var data = JSON.stringify(collectdata);
            var headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/interviews/get-candidate-interiew`, data, { headers });
            let testDatares = response.data;
            let dataRes = testDatares.interviews?.length > 0 ? testDatares.interviews : [];
            user.langId = dataRes[0].lang_id;
            setCandidateTestList(dataRes);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        setUserDetails(decryptedQuery);
        if(decryptedQuery?.email)
        {
            fetchTestInfo(decryptedQuery?.email)    
        }
    }, [])


    const InterviewerDetails = (info) => {
        if(info?.test_type == "2")
        navigate('/sheela-test-details', { state: info });
        else
        navigate('/test-details', { state: info });
    }

    return (
        <div className='totalTestListPage interviewList-page py-3'>
            <div className="container-fluid">

                {/* Candidate Profile Details */}
                <div className='card p-3 p-lg-4'>
                    <div className="candidate-details">
                        <div className="row align-items-center">
                            <div className="col-md-7 mt-2 mt-md-0">
                                <div className="profile-img mb-2">
                                    <img src={`https://interviewer-profile.s3.amazonaws.com/${decryptedQuery?.login_token}/${decryptedQuery?.login_token}.jpg`} referrerPolicy="no-referrer" alt="Candidate" className="img-fluid border border-3 border-primary rounded-circle object-fit-cover shadow" style={{ width: '110px', height: '110px' }} />
                                </div>

                                <div className='profile-info'>
                                    <div className="cand-name text-capitalize mb-2">
                                        <span className='fs-5 fw-medium'>{userDetails?.displayName}</span>
                                    </div>
                                    <div className="row g-2">
                                        <div className="col-sm-6 info-box small">
                                            <div className="info-label fw-medium text-body">Current Company:</div>
                                            <div className="info-value d-flex text-muted text-capitalize">
                                                <i className="bi bi-buildings-fill me-2"></i>{userDetails?.currentCompany}
                                            </div>
                                        </div>
                                        <div className="col-sm-6 info-box small">
                                            <div className="info-label fw-medium text-body">Contact Number:</div>
                                            <div className="info-value d-flex text-muted text-capitalize">
                                                <i className="bi bi-telephone-fill text-secondary me-2"></i>{userDetails?.contactNo}
                                            </div>
                                        </div>
                                        <div className="col-sm-6 info-box small">
                                            <div className="info-label fw-medium text-body">Total Experience:</div>
                                            <div className="info-value d-flex text-muted text-capitalize"><i className="bi bi-briefcase-fill me-2"></i>{userDetails?.totalExp} Years</div>
                                        </div>
                                        <div className="col-sm-6 info-box small">
                                            <div className="info-label fw-medium text-body">Current Working Status:</div>
                                            <div className="info-value d-flex text-muted text-capitalize"><i className="bi bi-briefcase-fill me-2"></i>{userDetails?.jobStatus}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='col-md-5 mt-3 mt-md-0'>
                                
                                <div className="fs-18 fw-medium text-capitalize mb-2"><i className="bi bi-film small text-primary"></i> Registration video recording -</div>
                                <div className="zoom-wrapper ratio ratio-16x9 overflow-hidden" style={{ maxWidth: '500px' }}>
                                    <video className='border rounded w-100 d-block object-fit-cover' height="240" controls >
                                    <source src={`https://interviewer-profile.s3.amazonaws.com/${decryptedQuery?.login_token}/videos/${decryptedQuery?.login_token}.webm`} type="video/webm" />
                                    Your browser does not support the video tag.
                                    </video>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="total-tests-wrapper">
                    {candidateTestList?.map((candidateTest, index) => 
                    {
                        let startMoment = moment(candidateTest?.testStarttime).utc();
                        let endMoment = moment(candidateTest?.testEndtime).utc();
                        let duration = moment.duration(endMoment.diff(startMoment));  
                        const formattedDate = startMoment.format('YYYY-MM-DD HH:mm:ss');
                        const formattedEndDate = endMoment.format('YYYY-MM-DD HH:mm:ss');

                       return (
                        <div className={`workingStatusCard card small rounded pointer overflow-auto mt-2 ${candidateTest?.test_type == '1' ? 'no-where-working' : 'on-notice-period' }`} key={index}>
                            <div className="info-header fs-6 text-white d-flex flex-wrap justify-content-between column-gap-2 text-capitalize text-end px-3 py-2">
                                <div className="workinStatuslabel text-black">Interview Type</div>
                                <div className="workinStatus text-black fw-medium">{candidateTest?.test_type == '1' ? "Coding Round" : "One-to-One Interview By Sheela"}</div>
                            </div>
                            <div className="info-cards overflow-auto p-3">
                                <div className="row g-2">
                                   
                                    <div className="col-sm-6 col-sm-6 col-md-4 col-xl-3 item-wrapper d-flex flex-column gap-2">
                                        <div className="info-box">
                                            <div className="cand-name fw-medium text-truncate text-capitalize">{candidateTest?.displayName}</div>
                                            {userDetails?.contactNo && <div className="cand-contact text-muted text-truncate text-capitalize"><i className="bi bi-telephone-fill text-body"></i> {userDetails?.contactNo}</div>}
                                        </div>
                                        <div className="info-box">
                                            <div className="info-label fw-medium text-body">Current Company</div>
                                            <div className="info-value text-muted text-capitalize">{userDetails?.currentCompany}</div>
                                        </div>
                                        <div className="info-box">
                                            <div className="info-label fw-medium text-body">Language</div>
                                            <div className="info-value text-muted text-capitalize">{candidateTest?.selectedLanguage}</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-sm-6 col-md-4 col-xl-3 item-wrapper d-flex flex-column gap-2">
                                        <div className="info-box">
                                            <div className="info-label fw-medium text-body">Total Questions Attempted</div>
                                            <div className="info-value text-muted text-capitalize">{candidateTest?.ques_attempt}</div>
                                        </div>
                                        <div className="info-box">
                                            <div className="info-label fw-medium text-body">Total Questions Skipped</div>
                                            <div className="info-value text-muted text-capitalize">{candidateTest?.skip_count}</div>
                                        </div>
                                        <div className="info-box">
                                            <div className="info-label fw-medium text-body">Total Interview Time</div>
                                            <div className="info-value text-muted text-capitalize">{candidateTest?.testDuration}</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-sm-6 col-md-4 col-xl-3 item-wrapper d-flex flex-column gap-2">
                                        <div className="info-box">
                                            <div className="info-label fw-medium text-body">When User started this round</div>
                                            <div className="info-value text-muted text-capitalize">{formattedDate}</div>
                                        </div>
                                        <div className="info-box action-wrapper">
                                            <div className="info-label fw-medium text-body">When user completed This round</div>
                                            <div className="info-value text-muted text-capitalize">{formattedEndDate}</div>
                                        </div>
                                        <div className="info-box action-wrapper">
                                            <div className="info-label fw-medium text-body">Result</div>
                                            <div className={`${candidateTest?.result === 'Pass' ? 'btn-success' : 'btn-danger'}`}>{candidateTest?.result}</div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-12 col-xl-3 item-wrapper">
                                        <div className='action-wrapper d-xl-flex align-items-xl-end justify-content-xl-end gap-2 mt-2 mt-xl-0'>
                                            <button type='button' className='btn btn-light text-nowrap border px-3' onClick={() => InterviewerDetails(candidateTest)}>View Details</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) 
                    
                    }
                    )}
                 </div>

                {/* Video Fullscreen Model Popup */}
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="xl" scrollable centered contentClassName="border-0 shadow">
                    <Modal.Header closeButton closeVariant="white" className='bg-primary bg-gradient fs-18 text-white text-capitalize lh-sm'>
                        <div className='text-white'><i className="bi bi-film small"></i> Inertview Video Recording</div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="ratio ratio-16x9 overflow-hidden">
                            <video className='border rounded w-100 d-block object-fit-cover' controls autoPlay>
                                <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" />
                                <source src="https://www.w3schools.com/html/mov_bbb.ogg" type="video/ogg" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}
