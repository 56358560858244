import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import imgDefault from '../assets/Avatar.png';
import searchIcon from '../assets/search.svg'
import { getAuth } from '../firebaseConfig';
import axios from 'axios';
import ExportToExcel from '../components/ExportToExcel';
import moment from 'moment';
import { Loader } from '../components/loader';
import { UserAuth } from '../context/AuthContext';
import { useMyContext } from '../context/MyContext';
import Pagination from '../components/Pagination';
import CryptoJS from 'crypto-js';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import { useDropzone } from 'react-dropzone';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Rating } from 'react-simple-star-rating'
import { ToastContainer, toast } from 'react-toastify';

export const HRAddedCandidatesList = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [candidateList, setCandidate] = useState([]);
  const [workingStatus, setWorkingStatus] = useState("");
  const [interviewMed, interviewMedChange] = useState("");

  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [error, setError] = useState(null);
  const [searchVal, setSearchVal] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const { user, userInfoLoaded, currentUserVal } = UserAuth();
  const { values, handleChange } = useMyContext()
  const [changehrtype, setChangeHRtype] = useState('')
  const [Imgerror, setImgError] = useState('Error: Failed to load image. Please check the URL and try again.');
  const [isDateSet, setDateVal] = useState(false);
  const [selectedCandidate, setCandidateSelected] = useState("");

  const [dashboardData, setDashboardData] = useState({
    inhouseHrCount: 0,
    outsourcedHrCount: 0,
    uniqueCandidateCount: 0,
    passCount: 0,
    failCount: 0
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [debouncedSearchVal, setDebouncedSearchVal] = useState("");
  const [isStartDayToday, setStartDay] = useState(true);
  const [scheduleClient, setScheduleClient] = useState([]);
  const [rating, setRating] = useState(0);
  const [culturalRating, setCulturalRating] = useState(0)
  const [gettotalHR, setTotalHR] = useState([])
  const [getHRname, setHRname] = useState('')

  const [formData, setFormData] = useState({
    user_name: '',
    email: '',
    contact_no: '',
    current_company: '',
    interview_date: '',
    interview_time: '',
    other_details: '',
    resume_url: null,
    added_by_email: '',
    added_by_name: '',
    job_status: '',
    notice_period: '',
    current_ctc: '',
    expected_ctc: '',
    source_of_candidate: '',
    is_notice_buyout: '',
    job_role: '',
    reason_job_change: '',
    salaryNegotiation: '',
    candidate_ques: '',
    interview_medium: '',
    cultural_fit: '',
    request_id: '',
    requisition_id: '',
  });

  // React Bootstrap Model
  const [show, setShow] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [file, setFile] = useState(null);
  const acceptedFileTypes = ['docx', 'pdf', 'doc'];
  const [phone, setPhone] = useState("");
  const [isDisable, setDisable] = useState(true);
  const [checkMessage, setMessage] = useState("");
  const [otherVisibility, setOtherVisibility] = useState(true);
  const [isProgress, setisProgress] = useState(false);
  const [isChecking, setisChecking] = useState(false);
  const [registrationStatus, setRegistrationStatus] = useState("");



  const [statuses] = useState([
    { value: 'Chase Up', label: 'Chase up Call' },
    { value: 'Not Picked', label: 'Not Picked up Call' },
    { value: 'Manager Round', label: 'Promoted for managerial Round' },
    { value: 'Offer Given', label: 'Offer Given' },
    { value: 'Not Interested', label: 'Not Interested' },
    { value: 'Too Much Salary Expectation', label: 'Too Much Salary Expectation' },
    // { value: 'Rejected in Second Round', label: 'Rejected in Second Round' },
    { value: 'Joined', label: 'Joined' },
    { value: 'Selected', label: 'Got Selected' },
    { value: 'Drop Out', label: 'Drop Out' },
    { value: 'Rejected', label: 'Rejected' }
  ]);
  const [nextStep, setNextStep] = useState('');
  const [note, setNote] = useState('');
  const openFilter = (event) => {
    setIsActive(current => !current);
  };

  const getHRList = async () => {
    const refreshedToken = await currentUserVal?.getIdToken(true);
    var datasend = {
      dataset: "dataset",
      hrType: user?.userType == "SA" ? "1" : ""
    };
    var headers = {
      'Authorization': `Bearer ${refreshedToken}`,
      'Content-Type': 'application/json'
    };
    axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/candidates/get-hr-list`, datasend, { headers })
      .then(function (response) {
        let HRalldata = response?.data.hrList;
        HRalldata.sort((a, b) => a.name.localeCompare(b.name));
        setTotalHR(HRalldata)
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  }
  const isStartDateToday = () => {
    if (moment(startDate).isSame(moment(), 'day'))
      setStartDay(true);
    else
      setStartDay(false);
  };

  const fetchDashboardBlock = async () => {
    setIsLoading(true);

    if (user?.accessToken) {
      let fetchobj = {};
      try {
        fetchobj = {
          fromDate: startDate,
          toDate: endDate,
          userType: user?.userType,
          searchString: debouncedSearchVal,
          ...(user?.userType === "HR" && { eMailId: user?.email }),
          ...(getHRname != "" && { eMailId: getHRname }),
          ...(registrationStatus != "" && { is_registered: registrationStatus }),
          ...(interviewMed != "" && { interview_medium: interviewMed }),
        };
        const vereqMatch = debouncedSearchVal.match(/VEREQ(\d+)/i);
        if (vereqMatch) {
          const requisitionNumber = vereqMatch[1]; // Extracts "362" from "VEREQ362"
          fetchobj.is_searchByRequisition = 1; // Set the flag
          fetchobj.searchString = requisitionNumber; // Use the extracted number instead of the full string
        }
        if (user?.userType == "MGR" || user?.userType == "SMGR") {
          fetchobj.userType = user?.userType;
          fetchobj.managerEmail = user?.email;
        }
        const refreshedToken = await currentUserVal?.getIdToken(true);
        let getToken = user?.accessToken;
        var data = JSON.stringify(fetchobj);
        var headers = {
          'Authorization': `Bearer ${refreshedToken}`,
          'Content-Type': 'application/json'
        };
        const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/candidates/get-count`, data, { headers });
        const responseData = response ? response : '';
        setDashboardData(responseData?.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchVal(searchVal);
    }, 500);

    return () => {
      clearTimeout(handler); // Clear the timeout if searchVal changes before 500ms
    };
  }, [searchVal]);

  const hrdata = (e) => {
    setHRname(e.target.value)
    setCurrentPage(1)
  }


  useEffect(() => {
    const fetchList = async () => {
      setIsLoading(true);

      const filterObj = {
        fromDate: startDate,
        toDate: endDate,
        searchString: debouncedSearchVal,
        test_type: "",
        page: currentPage,
        pageSize: 10,
        userType: user?.userType,
        ...(user?.userType === "HR" && { eMailId: user?.email }),
        ...(changehrtype && { hrType: changehrtype }),
        ...(interviewMed != "" && { interview_medium: interviewMed }),
        ...(registrationStatus != "" && { is_registered: registrationStatus }),
        ...(getHRname != "" && { eMailId: getHRname })
      };
      const vereqMatch = debouncedSearchVal.match(/VEREQ(\d+)/i);
      if (vereqMatch) {
        const requisitionNumber = vereqMatch[1]; // Extracts "362" from "VEREQ362"
        filterObj.is_searchByRequisition = 1; // Set the flag
        filterObj.searchString = requisitionNumber; // Use the extracted number instead of the full string
      }
      if (user?.userType == "MGR" || user?.userType == "SMGR") {
        filterObj.userType = user?.userType;
        filterObj.managerEmail = user?.email;
      }
      try {
        const refreshedToken = await currentUserVal?.getIdToken(true);
        const data = JSON.stringify(filterObj);
        const headers = {
          'Authorization': `Bearer ${refreshedToken}`,
          'Content-Type': 'application/json'
        };

        const response = await axios.post(
          `${process.env.REACT_APP_ADMIN_API}/v3.0/candidates/get-schdule-candidate-list-new`,
          data,
          { headers }
        );

        let userDatares = response.data;
        let dataVal = userDatares.list?.length > 0 ? userDatares.list : [];
        setCandidate(dataVal);
        setTotalPages(userDatares?.totalPage);
        setItemsPerPage(userDatares?.limit);
        //setCurrentPage(userDatares?.currentPage);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching list:", error);
        setError("Failed to fetch data");
      } finally {
        setIsLoading(false);
      }
    };
    isStartDateToday();
    fetchDashboardBlock();
    fetchList();
    getHRList()

  }, [currentUserVal, interviewMed, debouncedSearchVal, changehrtype, startDate, endDate, currentPage, user, registrationStatus, getHRname]);


  //On InterviewMediumChange
  const InterviewMediumChange = (event) => {
    setCurrentPage(1);
    setError(null);
    interviewMedChange(event.target.value);
  }

  //On InterviewMediumChange
  const registrationStatusChange = (event) => {
    setCurrentPage(1);
    setError(null);
    setRegistrationStatus(event.target.value);
  }



  const HRstatuschange = (e) => {
    setCurrentPage(1);
    setError(null);
    setChangeHRtype(e.target.value);
  }


  const handleStartDateChange = (event) => {
    setCurrentPage(1);
    setError(null);
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setError(null);
    setCurrentPage(1);
    if (!startDate && event.target.value != "") {
      setError("Please select start Date before end Date.");
      setDateVal(false);
      return;
    }
    if (startDate > event.target.value && event.target.value != "") {
      setError("End Date cannot be less than the start Date.");
      setDateVal(false);
      return;
    }
    setEndDate(event.target.value);
    setDateVal(event.target.value != "" ? true : false);
  };

  const searchFunction = (event) => {
    setCurrentPage(1);
    let searchText = event.target.value;
    setSearchVal(searchText);
  }


  const InterviewerDetails = (info) => {
    let token = "";
    if (info?.user_photo != "") {
      token = info?.user_photo.match(/\/([^/]+)\.[^.]+$/)[1];
    }

    let sendInfo = {
      "userId": info?.userId,
      "email": info?.userEmail,
      "displayName": info?.displayName,
      "login_token": token,
      "currentCompany": info?.currentCompany,
      "totalExp": info?.totalExp,
      "jobStatus": info?.jobStatus,
      "contactNo": info?.contactNo
    }
    const encryptedQuery = CryptoJS.AES.encrypt(JSON.stringify(sendInfo), 'secret_key').toString();

    navigate(`/total-test-list?query=${encodeURIComponent(encryptedQuery)}`);
  }

  const clickBox = (data) => {
    navigate(`/${data}`);
    // console.log(data)
  }

  const handleImageError = () => {
    setImgError('');
  };

  // pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleRating = (rate) => {
    setRating(rate)
  }

  const handleCulturalRating = (rate) => {
    setCulturalRating(rate)
  }
  // pagination
  // Reset date filters function
  const resetDateFilters = () => {
    setStartDate('');
    setEndDate('');
  };
  const handleClose = () => setShow(false);


  const openStatusModal = () => setShowStatusModal(true);
  const closeStatusModal = () => setShowStatusModal(false);

  const CandidateDetails = (info, btntype) => {
    // let requisitionId = info?.request_id;
    // let sendInfo = { "requisitionId": requisitionId, "email": info?.email }
    // if (btntype == 'viewDetail') {
    //   const encryptedQuery = CryptoJS.AES.encrypt(JSON.stringify(sendInfo), 'secret_key').toString();
    //   navigate(`/hr-candidate-details?query=${encodeURIComponent(encryptedQuery)}`);
    // }
    const encryptedQuery = CryptoJS.AES.encrypt(JSON.stringify(info), 'secret_key').toString();
    navigate(`/candidate-details?query=${encodeURIComponent(encryptedQuery)}`);
  }
  const handleDrop = useCallback((acceptedFiles) => {
    setError("");
    const selectedFile = acceptedFiles[0];
    if (!acceptedFileTypes.includes(selectedFile.name.split('.').pop())) {
      setError(`File ${selectedFile.name} has an invalid file type. Only ${acceptedFileTypes.join(', ')} are accepted.`);
      return;
    }

    setFile(selectedFile);
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };
  //select candidate and show in Modal
  const addCandidateStatus = (candidateInfo) => {
    // console.log(candidateInfo);
    setCandidateSelected(candidateInfo);
    openStatusModal();
  }
  const uploadResume = async () => {
    if (error === "") {
      if (file) {
        setIsLoading(true);
        const fileVal = file || {};
        const fileName = file?.name;
        const fileType = file?.type;

        try {
          // Get the Firebase authentication token
          const refreshedToken = await user?.getIdToken(true);

          // Include the token in the request headers
          const headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'multipart/form-data', // Set content type to form data
          };

          const formData = new FormData();
          formData.append('file', fileVal);
          formData.append('bucketName', 'interviewer-profile');
          formData.append('key', `/ResumeByHR/${fileName}`);
          formData.append('contentType', fileType);
          formData.append('isEncoding', false);
          formData.append('ContentEncoding', '');
          const response = await axios.post(process.env.REACT_APP_S3UPLOAD, formData, { headers });

          setIsLoading(false);
          return response.data.uploadResponse;
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      }
    } else {
      setIsLoading(false);
      setError("Please select appropriate file type and mark agree to upload.");
      return;
    }
  };

  //Check email
  const checkEmail = async () => {
    try {
      setisChecking(true);
      const refreshedToken = await currentUserVal?.getIdToken(true);
      const data = JSON.stringify({
        "operation": "checkcandidate",
        "checkby": "email",
        "email": formData?.email
      });

      const headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json'
      };

      // Make a POST request to the API endpoint
      const response = await axios.post(process.env.REACT_APP_API_URL, data, { headers });
      let isuserExist = response.data?.data;
      if (isuserExist?.isExist == "1") {
        setisChecking(false);
        setMessage(`Added by ${isuserExist?.added_by} on ${moment(isuserExist?.created).utc().format('YYYY-MM-DD')}`);
        setOtherVisibility(true);
      }
      else {
        setOtherVisibility(false);
        setMessage("");
        setisChecking(false);
      }
    } catch (error) {
      // Handle errors that occurred during the request
      // console.log(error);
      setisChecking(false);

      // You might want to throw the error or handle it differently based on your needs
      throw error;
    }
  }

  //Check contact Number exist or Not
  const checkCandidateContact = async (contact_no) => {
    try {
      setisChecking(true);
      const refreshedToken = await currentUserVal?.getIdToken(true);
      const data = JSON.stringify({
        "operation": "checkcandidate",
        "checkby": "number",
        "contact_no": contact_no
      });
      const headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json'
      };

      // Make a POST request to the API endpoint
      const response = await axios.post(process.env.REACT_APP_API_URL, data, { headers });
      setisChecking(false);

      // Return the response data
      return response.data?.data;
    } catch (error) {
      // Handle errors that occurred during the request
      setisChecking(false);
      // console.log(error);
      // You might want to throw the error or handle it differently based on your needs
      throw error;
    }
  };


  const handleOnChangeNumber = (contact_val, country) => {
    const phoneInput = document.querySelector('.react-tel-input input');
    const parsedNumber = phoneInput?.value;
    setPhone(contact_val);

    if (parsedNumber) {
      const cleanNumber = parsedNumber.replace(/[\s()-]/g, '');
      const localNumber = cleanNumber.replace(`+${country.dialCode}`, '');
      if (localNumber.length == 10) {
        checkCandidateContact(contact_val)
          .then((response) => {

            if (response?.isExist == 1) {
              setMessage(`Added by ${response?.added_by} on ${moment(response?.created).utc().format('YYYY-MM-DD')}`);
              setDisable(true);
            }
            else {
              setDisable(false);
              setMessage("");
            }
            // Do something with the result
          })
          .catch((error) => {
            console.error("Error checking candidate contact:", error);
            // Handle the error
          });
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let candidateData = formData;
    candidateData.added_by_email = user?.email;
    candidateData.added_by_name = user?.displayName;
    candidateData.added_by_id = user?.uid;
    candidateData.contact_no = phone;
    candidateData.comm_skill_rating = rating;
    candidateData.cultural_fit_rating = culturalRating;
    if (file) {
      setisProgress(true);
      const url = await uploadResume();
      // console.log(url);
      candidateData.resume_url = url;
      setFormData(candidateData);

      //let getToken = await auth?.currentUser?.getIdToken();
      // let getToken = user?.accessToken;
      const refreshedToken = await currentUserVal?.getIdToken(true);
      candidateData.operation = "addcandidate";
      var data = JSON.stringify(candidateData);
      var headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json'
      };
      axios.post(process.env.REACT_APP_API_URL, data, { headers })
        .then(function (response) {
          setIsLoading(false);
          setisProgress(false);
          handleClose();

        }).catch(function (error) {
          // console.log(error);
          setisProgress(false);
          setIsLoading(false);
        });
    }
    else {
      setIsLoading(false);
      setisProgress(false);
      setError("Please select candidate Resume.");
    }
  };

  function Dropzone({ onDrop }) {
    const onDropCallback = useCallback((acceptedFiles) => {
      onDrop(acceptedFiles);
    }, [onDrop]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: onDropCallback,
      accept: acceptedFileTypes.join(','),
    });

    return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div className='upload-icon mb-2'><i className="bi bi-cloud-arrow-up-fill fs-3 text-success lh-1"></i></div>
        {
          isDragActive ?
            <p>Drop the files here ...</p> :
            <div className='text-muted'>
              <p>Drop your candidate resume here or Browse.<br /> File should be (DOCX, PDF, DOC ) only</p>
              <button className='btn btn-sm btn-success rounded-pill px-3'>Browse File</button>
            </div>
        }
      </div>
    );
  }
  const handleSubmitStatus = async (event) => {
    event.preventDefault();
    // Handle form submission, e.g., send data to API
    // console.log('Status:', event.target.status.value);
    // console.log('Next Step:', nextStep);
    // console.log('Note:', note);

    const refreshedToken = await currentUserVal?.getIdToken(true);
    var headers = {
      'Authorization': `Bearer ${refreshedToken}`,
      'Content-Type': 'application/json'
    };
    try {
      setisProgress(true);
      const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/add-status`, {
        requisitionId: selectedCandidate?.request_id,
        name: selectedCandidate?.user_name,
        email: selectedCandidate?.email,
        type: 'candidate',
        status: event.target.status.value,
        nextStep: nextStep,
        note: note,
        addedBy: user?.displayName,
        addedByEmail: user?.email,
        is_statusChange: 1
      }, { headers });
      // console.log('Form submitted successfully');
      setNextStep('');
      setNote('');
      setisProgress(false);
      closeStatusModal();
      toast.success("Status has been updated.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    } catch (error) {
      setisProgress(false);
      console.error('Error submitting form:', error);
      // Handle error
    }
  };

  const getUserStatus = (is_registered, is_registration_completed, is_interviewGiven) => {
    // if (is_interviewGiven === 1) {
    //     return "Interview Conducted";
    // } 
    if (is_registered === 1 && is_registration_completed === 1) {
      return "Registration Completed";
    } else if (is_registered === 1 && is_registration_completed === 0) {
      return "Partial Registration";
    } else if (is_registered === 0) {
      return "Not Registered";
    }
  };


  const getStatusClassName = (status) => {
    switch (status) {
      case "Interview Conducted":
        return "interview-conducted";
      case "Registration Completed":
        return "registered";
      case "Partial Registration":
        return "partially-registered";
      case "Not Registered":
        return "not-registered";
      default:
        return "";
    }
  };
  return (
    <div className='interviewList-page dashboard-home pb-3'>
      <div className="container-fluid">
        <div className="wrapper sticky-md-top mb-3 pt-3" style={{ top: '56px', backgroundColor: '#dddfe6', zIndex: '1010' }}>
          {/* Filter Section*/}
          <div className='filter-sec mb-2'>
            {/* <div className="d-flex align-items-center justify-content-between gap-2">
            <div className="filter-label fw-medium pointer" onClick={openFilter}><i className="bi bi-filter-circle-fill" title='Click to filter'></i> Filter By -</div>
            <div className="filter-label fw-medium pointer"></div>

            {candidateList?.length > 0 ?
              <ExportToExcel data={candidateList} filename="my_data" /> :
              null
            }
          </div> */}
            <div className={`d-flex flex-column flex-md-row align-items-md-center justify-content-between gap-1 gap-xl-2 mt-2`}>
              <div className="searchfield-wrapper flex-fill">
                <input type="search" className="form-control rounded-2" id="search" value={searchVal} placeholder="Search by Candidate Name or Requisition ID" onChange={searchFunction} />
                <img src={searchIcon} alt="Search Icon" className="position-absolute top-50 end-0 translate-middle" />
              </div>
              {user?.userType == 'SA' || user?.userType == 'SSA' ?
                <div className="Result-filter flex-fill">
                  <select value={getHRname} onChange={hrdata} className="form-select" autoComplete='off' required>
                    <option value="">Select HR</option>
                    {gettotalHR.map((info, id) => (
                      <option value={info?.email} className="text-capitalize">{info?.name}</option>
                    ))}
                  </select>
                </div> : ''}

              <div className="status-filter flex-fill">
                <select className="form-select" value={registrationStatus} aria-label="Default select example" onChange={registrationStatusChange} required>
                  <option value="">Select Type</option>
                  <option value="1">Registered Candidate</option>
                  <option value="2">Incomplete Registered</option>
                  <option value="0">Not Registered</option>
                </select>
              </div>

              <div className="status-filter flex-fill">
                <select className="form-select" value={interviewMed} aria-label="Default select example" onChange={InterviewMediumChange} required>
                  <option value="">Select Medium</option>
                  <option value="NM Interview">NM Interview</option>
                  <option value="In Person">In Person</option>
                  <option value="Other Interview Tool">Other Interview Tool</option>
                </select>
              </div>

              <div className="date-filter d-flex align-items-center gap-1 bg-white rounded-2 px-3">
                <input type="date" className="form-control border-0 ps-0" id="startDate" value={startDate} onChange={handleStartDateChange} required />
                <input type="date" className="form-control border-0 ps-0" id="endDate" value={endDate} onChange={handleEndDateChange} required />
                <button type='button' className='btn btn-sm btn-primary px-3 lh-1' onClick={() => resetDateFilters()}>Reset</button>
              </div>
            </div>
            {error && <p className="text-danger">{error}</p>}
          </div>
          {/*Info boxes Section*/}

          <div className='info-card-sec mb-3'>
            <div className='row g-2 g-md-3'>
              <div className='col-sm-6 col-xl-3 '>
                <div className='bg-primary bg-opacity-25 border-bottom border-4 border-primary rounded-3 pointer h-100 p-3 p-xl-4'>
                  <div className="d-flex align-items-center justify-content-between gap-3">
                    <div className='card-details'>
                      <div className="label-value fs-3 fw-bold lh-1 mb-1">{dashboardData?.interviewScheduleCount
                      }</div>
                      <div className="label-title small text-muted lh-sm">Total Scheduled by Recruiter</div>
                    </div>
                    <div className='card-iconn text-primary lh-1'>
                      <i className="bi bi-people-fill fs-1"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-sm-6 col-xl-3'>
                <div className='bg-warning bg-opacity-25 border-bottom border-4 border-warning rounded-3 pointer h-100 p-3 p-xl-4'>
                  <div className="d-flex align-items-center justify-content-between gap-3">
                    <div className='card-details'>
                      <div className="label-value fs-3 fw-bold lh-1 mb-1">{dashboardData?.registerCount}</div>
                      <div className="label-title small text-muted lh-sm">Portal Registration Started</div>
                    </div>
                    <div className='card-iconn text-warning lh-1'>
                      <i className="bi bi-person-fill-up fs-1"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-sm-6 col-xl-3'>
                <div className='bg-success bg-opacity-25 border-bottom border-4 border-success rounded-3 pointer h-100 p-3 p-xl-4' >
                  <div className="d-flex align-items-center justify-content-between gap-3">
                    <div className='card-details'>
                      <div className="label-value fs-3 fw-bold lh-1 mb-1">{dashboardData?.registerCompletedCount}</div>
                      <div className="label-title small text-muted lh-sm">Portal Registration Completed</div>
                    </div>
                    <div className='card-iconn text-success lh-1'>
                      <i className="bi bi-person-fill-check fs-1"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-sm-6 col-xl-3'>
                <div className='bg-info bg-opacity-25 border-bottom border-4 border-info rounded-3 pointer h-100 p-3 p-xl-4'>
                  <div className="d-flex align-items-center justify-content-between gap-3">
                    <div className='card-details'>
                      <div className="label-value fs-3 fw-bold lh-1 mb-1">{dashboardData?.partialCompletedCount}</div>
                      <div className="label-title small text-muted lh-sm">Partial Portal Registration</div>
                    </div>
                    <div className='card-iconn text-info lh-1'>
                      <i className="bi bi-person-fill-up fs-1"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='candidates-wrapper'>
          {/* When loader visible */}
          {isLoading ? (
            <Loader />
          ) : ''}

          <div className='candidate-list d-flex flex-column gap-3'>
            {candidateList?.length > 0 && candidateList?.map((info, id) => {
              const originalMoment = moment(info?.interview_date).utc();
              const formattedDate = originalMoment.format('YYYY-MM-DD');
              const status = getUserStatus(info.is_registered, info.is_registration_completed, info.is_interviewGiven);
              //const status = getUserStatus(1,0,0);

              const statusClassName = getStatusClassName(status);

              return (
                <div className={`candidateStatusCard bg-white small rounded-3 pointer overflow-auto p-3 ${statusClassName}`} key={id}>
                  <div class="info-header border-bottom pb-2 mb-2">
                    <div class="row g-2">
                      <div className="col-12 col-md-4">
                        <div className="candidate-info">
                          <div className="candidate-name fs-5 text-truncate text-capitalize lh-sm">{info.user_name}</div>
                          {info.email && <div className="cand-email text-lowercase">{info.email}</div>}
                          <div className="cand-email text-capitalize">{info.contact_no}</div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4">
                        <div className="candidate-info fs-6 fw-semibold text-md-center">
                          <div className="info-label">Requisition Id:</div>
                          <div className="cand-requisition-id text-capitalize">{`VEREQ${info?.requisition_id}`}</div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4">
                        <div className="btn-wrapper text-sm-end">
                          <Button variant="light" className="custom-button fw-medium rounded-pill py-2">
                            {status}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 g-xl-2">
                    <div className="col-6 col-md-3 item-wrapper d-flex flex-column">
                      <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Current Company</div>
                        <div className="cand-company text-capitalize">{info.current_company}</div>
                      </div>
                      <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Job Status</div>
                        <div className="text-capitalize">{info.job_status}</div>
                      </div>
                      <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Job Role Awareness</div>
                        <div className="text-capitalize">{info?.is_job_role_aware}</div>
                      </div>
                      <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Source of Candidate</div>
                        <div className="text-capitalize">{info.source_of_candidate}</div>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 item-wrapper d-flex flex-column">
                      <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Notice Period</div>
                        <div className="text-capitalize">{info?.notice_period}</div>
                      </div>
                      <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Current CTC</div>
                        <div className="text-capitalize">{info.current_ctc}</div>
                      </div>
                      <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Expected CTC</div>
                        <div className="text-capitalize">{info.expected_ctc}</div>
                      </div>
                      <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Salary Negotiation</div>
                        <div className="text-capitalize">{info?.is_salary_negotiation}</div>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 item-wrapper d-flex flex-column">
                      <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Comm. Skill Rating</div>
                        <div className="text-capitalize">{info?.comm_skill_rating}/5</div>
                      </div>
                      <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Cultural Fit Rating</div>
                        <div className="text-capitalize">{info?.cultural_fit_rating}/5</div>
                      </div>
                      <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Interview Medium</div>
                        <div className="text-capitalize">{info.interview_medium}</div>
                      </div>
                      <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Interview Date & Time</div>
                        <div className="regis-date text-capitalize">{moment(info?.interview_date).utc().format('YYYY-MM-DD')}, {info?.interview_time}</div>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 item-wrapper d-flex flex-column">
                      <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Added By</div>
                        <div className="text-capitalize">{info.added_by_name}</div>
                      </div>
                      <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Time Since Added</div>
                        <div className="text-capitalize">{info?.timeAgo}</div>
                      </div>
                      <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Registration Status</div>
                        <div className="text-capitalize">{status}</div>
                      </div>
                      <div className="action-wrapper d-flex flex-wrap gap-2 mt-1">
                        <Button type='button' variant="light" size="sm" className="border px-3" onClick={() => CandidateDetails(info, 'viewDetail')}>View Details</Button>
                        {user?.userType === "HR" ?
                          <Button type='button' variant="light" size="sm" className="border px-3" onClick={() => addCandidateStatus(info)}> Add Status</Button> :
                          null}
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            )}
          </div>
          {/* new pagination */}
          {candidateList?.length >= 1 ?
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            : ''}
          {/* new pagination */}

          {/* No data available */}
          {candidateList?.length == 0 ? (
            <div className="no-data-wrapper bg-white rounded px-3 py-5 mt-2">
              <div className="text-center mx-auto" style={{ maxWidth: '350px' }}>
                <img src={require('../assets/no-data.jpg')} alt="Candidate" className="img-fluid" />
                <div className='fs-4 fw-semibold text-danger mt-3'>Sorry no record Found !</div>
                <div className='small text-muted'>Whoops... this information is not available for a moment</div>
              </div>
            </div>) : null
          }
        </div>
      </div>
      {/* Add New Candidate Model */}
      <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false} size="xl" contentClassName="border-0 border-5 border-top border-primary rounded-0 rounded-bottom">
        <Modal.Body className="modal-body px-3 px-xl-5 py-4">
          <h1 className="modal-title fs-4 fw-fw-semibold text-center mb-3" id="addCandidate">Add New Candidate</h1>
          <Form onSubmit={handleSubmit}>
            <div className="row g-2 gx-sm-3">
              <div className="col-sm-6 col-lg-4">
                <Form.Group>
                  <Form.Label>Contact</Form.Label>
                  <PhoneInput
                    country={"in"}
                    countryCodeEditable={false}
                    inputClass="w-100 h-100 border"
                    autoComplete={'false'}
                    value={phone}
                    onChange={handleOnChangeNumber}
                    inputProps={{
                      name: 'phone',
                      autoComplete: 'off',
                      required: true
                    }}
                    style={{ height: '45px' }}
                  />
                  <div className="small text-danger mt-1">{checkMessage}</div>
                </Form.Group>
              </div>
              <div className="col-sm-6 col-lg-4">
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    autoComplete="off"
                    disabled={isDisable}
                    onBlur={checkEmail}
                    required />
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group>
                  <Form.Label>Source Of Candidate Options </Form.Label>
                  <Form.Select name="source_of_candidate" onChange={handleInputChange} required
                    disabled={otherVisibility}
                  >
                    <option value={""}>Select Source</option>
                    <option value={"job_boards"}>Job Boards (e.g., Indeed, Monster need text box)</option>
                    <option value={"company_website"}>Company Website</option>
                    <option value={"social_media"}>Social Media (e.g., LinkedIn, Twitter, Facebook)</option>
                    <option value={"employee_referral"}>Employee Referral</option>
                    <option value={"recruitment_agencies"}>Recruitment Agencies</option>
                    <option value={"campus_recruitment"}>Campus Recruitment</option>
                    <option value={"networking_events"}>Networking Events</option>
                    <option value={"internal_application"}>Internal Application</option>
                    <option value={"headhunting"}>Headhunting</option>
                    <option value={"others"}>Others</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </div>

            <div className={otherVisibility ? "d-none" : ""}>
              {/* Verification of Basic Details Section */}
              <div id="basicDetails" className="row g-2 gx-sm-3 mt-1">
                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control type="text" name="user_name" value={formData.user_name} onChange={handleInputChange} autoComplete="off" required />
                  </Form.Group>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Current Company</Form.Label>
                    <Form.Control type="text" name="current_company" value={formData.current_company} onChange={handleInputChange} autoComplete="off" required />
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group>
                    <Form.Label>Job status</Form.Label>
                    <Form.Select name="job_status" onChange={handleInputChange} required>
                      <option value="">Select Job Status</option>
                      <option value="In Job">In Job</option>
                      <option value="Notice Period">Notice Period</option>
                      <option value="Nowhere Working">Nowhere Working</option>
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>

              {/* Suitability and Expectations Section */}
              <div id="interviewSchedule" className="row g-2 gx-sm-3 mt-1">
                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Current CTC</Form.Label>
                    <Form.Control type="text" name="current_ctc" value={formData.current_ctc} onChange={handleInputChange} autoComplete="off" required />
                  </Form.Group>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Expected CTC</Form.Label>
                    <Form.Control type="text" name="expected_ctc" value={formData.expected_ctc} onChange={handleInputChange} autoComplete="off" required />
                  </Form.Group>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Notice Period</Form.Label>
                    <Form.Select name="notice_period" onChange={handleInputChange} required>
                      <option value="">Select Notice Period</option>
                      <option value="Immidiate Joiner">Immidiate Joiner</option>
                      <option value="15 Days">15 Days</option>
                      <option value="1 Month">1 Month</option>
                      <option value="2 Month">2 Month</option>
                      <option value="3 Month">3 Month</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Possibility Of Notice BuyOut</Form.Label>
                    <Form.Select name="is_notice_buyout" onChange={handleInputChange} required>
                      <option value="">Select Notice Possibility</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Job Role</Form.Label>
                    <Form.Control type="text"
                      name="job_role"
                      value={formData.job_role}
                      onChange={handleInputChange}
                      autoComplete="off" required />
                  </Form.Group>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Reason For Job Change</Form.Label>
                    <Form.Control type="text"
                      name="reason_job_change"
                      value={formData.reason_job_change}
                      onChange={handleInputChange}
                      autoComplete="off" required />
                  </Form.Group>
                </div>
                <div className="col-12 mt-4">
                  <Form.Group className="d-flex align-items-center flex-wrap gap-4 h-100">
                    <Form.Label>Possibility Of Salary Negotiation</Form.Label>
                    <div className="">
                      <Form.Check
                        inline
                        label="Yes"
                        value="Yes"
                        name="salaryNegotiation"
                        type="radio"
                        checked={formData.salaryNegotiation == 'Yes' ? true : false}
                        onChange={handleInputChange}

                        id="inline-radio-1"
                        required
                      />

                      <Form.Check
                        inline
                        label="No"
                        name="salaryNegotiation"
                        type="radio"
                        value="No"
                        checked={formData.salaryNegotiation == 'No' ? true : false}
                        id="inline-radio-2"
                        onChange={handleInputChange}
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>

              {/* Upload Section */}
              <div id="quesAndResume" className="row g-2 gx-sm-3 mt-1">
                <div className="col-sm-6">
                  <Form.Group>
                    <Form.Label>Candidate Questions</Form.Label>
                    <Form.Control as="textarea" rows={5} name="candidate_ques"
                      value={formData.candidate_ques}
                      onChange={handleInputChange}
                      autoComplete="off" required />
                  </Form.Group>
                </div>
                <div className="col-sm-6">
                  <Form.Group>
                    <Form.Label>Upload File</Form.Label>
                    <label htmlFor="uploadResume" className="w-100 bg-warning bg-opacity-10 border-warning rounded-3 small text-center py-2" style={{ border: '1.5px dashed' }}>
                      <Dropzone acceptedFiles={['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'application/msword']} onDrop={handleDrop} />
                      {file ?
                        <p key={file.name} className='bg-light border py-2 px-3 rounded-pill small text-truncate mx-auto mt-3' style={{ maxWidth: '300px' }}>
                          {file.name} ({file.size} bytes)
                        </p> : null
                      }
                    </label>
                    {error && <p className="small text-danger mt-2">{error}</p>}
                  </Form.Group>
                </div>
              </div>

              {/* Rating Section */}
              <div id="culturalFit" className="row g-2 gx-sm-3 mt-1">
                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Communication Skills</Form.Label>
                    <div className='start-rating d-flex align-items-center gap-3'>
                      <Rating onClick={handleRating} size={35}
                        initialValue={rating}
                        allowFraction
                        // showTooltip 
                        fillColorArray={['#f14f45', '#f16c45', '#f18845', '#f1b345', '#f1d045']}
                      />
                    </div>
                  </Form.Group>
                </div>

                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Cultural Fit</Form.Label>
                    <div className='start-rating d-flex align-items-center gap-3'>
                      <Rating onClick={handleCulturalRating} size={35}
                        initialValue={culturalRating}
                        allowFraction
                        // showTooltip 
                        fillColorArray={['#f14f45', '#f16c45', '#f18845', '#f1b345', '#f1d045']}
                      />
                    </div>
                  </Form.Group>
                </div>

                <div className="col-lg-4">
                  <Form.Group>
                    <Form.Label>Reason for Cultural Fit</Form.Label>
                    <Form.Control as="textarea"
                      rows={2} name="cultural_fit"
                      autoComplete="off"
                      onChange={handleInputChange}
                      required />
                  </Form.Group>
                </div>
              </div>

              {/* Interview Schedule Section */}
              <div id="interviewSchedule" className="row g-2 gx-sm-3 mt-1">
                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Interview Date</Form.Label>
                    <Form.Control type="date" name="interview_date" value={formData.interview_date} onChange={handleInputChange} required />
                  </Form.Group>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Interview Time</Form.Label>
                    <Form.Control type="time" name="interview_time" value={formData.interview_time} onChange={handleInputChange} required />
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group>
                    <Form.Label>Interview Medium</Form.Label>
                    <Form.Select name="interview_medium" onChange={handleInputChange} required>
                      <option value="">Select Medium</option>
                      <option value="Online Interview">Online Interview</option>
                      <option value="Offline Interview">Offline Interview</option>
                      <option value="1 Month">NMLive</option>
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
            </div>
            {isChecking ? <Spinner className="ms-2 border-3" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}

            <div className="btn-wrapper text-center mt-4">
              <Button variant="secondary" className="px-3 me-2" onClick={handleClose}>Cancel</Button>
              <Button variant="primary" type="submit" className="px-3" disabled={otherVisibility ? true : false}>Submit
                {isProgress ? <Spinner className="ms-2 border-3" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Add Requisition Status Model */}
      <Modal show={showStatusModal} onHide={closeStatusModal} backdrop="static" keyboard={false} size="xl" centered contentClassName="rounded-4">
        <Modal.Body className="p-3 py-4 p-lg-5">
          {isLoading ? (
            <Loader />
          ) : ''}
          <div className="fs-3 fw-semibold text-center mb-2 mb-lg-4 mt-lg-n2">Add Candidate Status</div>
          <div className="row g-3 g-xl-4">
            <div className="col-6">
              <div className="info_wrapper d-xl-flex gap-3 gap-md-4">
                <div className="col-xl-4 title_name text-secondary text-xl-end">Candidate Name</div>
                <div className="value text-capitalize">{selectedCandidate?.user_name}</div>
              </div>
            </div>
            <div className="col-6">
              <div className="info_wrapper d-xl-flex gap-3 gap-md-4">
                <div className="col-xl-4 title_name text-secondary text-xl-end">Contact No</div>
                <div className="value text-capitalize">{selectedCandidate?.contact_no}</div>
              </div>
            </div>
            <div className="col-6">
              <div className="info_wrapper d-xl-flex gap-3 gap-md-4">
                <div className="col-xl-4 title_name text-secondary text-xl-end">Current company</div>
                <div className="value text-capitalize">{selectedCandidate?.current_company}</div>
              </div>
            </div>
            <div className="col-6">
              <div className="info_wrapper d-xl-flex gap-3 gap-md-4">
                <div className="col-xl-4 title_name text-secondary text-xl-end">email</div>
                <div className="value text-capitalize">{selectedCandidate?.email}</div>
              </div>
            </div>

            {/* Divider */}
            {/* <hr className="d-none d-xl-block divider border-secondary border-opacity-75 mt-4 mb-0" /> */}
            <div className="col-12">
              <Form onSubmit={handleSubmitStatus}>
                <Form.Select className="mb-3" aria-label="Default select example" name="status" required>
                  <option value="">Select Status</option>
                  {statuses.map((status, index) => (
                    <option key={index} value={status.value}>{status.label}</option>
                  ))}
                </Form.Select>
                <Form.Group className="mb-3">
                  <Form.Control type='text' value={nextStep} onChange={(e) => setNextStep(e.target.value)} placeholder="Next Step To be Taken" required />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control as="textarea" className="fs-6" value={note} onChange={(e) => setNote(e.target.value)} rows={4} placeholder="Note..." required />
                </Form.Group>
                <div className="btn_wrapper d-flex justify-content-center gap-3">
                  <Button type="button" variant="secondary" className="py-2 px-5" onClick={closeStatusModal}>Cancel</Button>
                  <Button type="submit" variant="success" className="py-2 px-5">Submit {isProgress ? <Spinner className="ms-2 border-3" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </div >

  )
}
