import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-medium-image-zoom/dist/styles.css';
import ModalImage from "react-modal-image";
import Modal from 'react-bootstrap/Modal';
import { Button, Tab, Tabs } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import moment from 'moment';
import { getAuth, database, secondDatabase } from '../firebaseConfig';
import axios from 'axios';
import { Loader } from '../components/loader';
import { UserAuth } from '../context/AuthContext';
import CryptoJS from 'crypto-js';
 
import AWS from 'aws-sdk';
import {
    collection,
    addDoc,
    doc,
    setDoc,
    getDocs,
    query,
    where,
    onSnapshot,
    orderBy,
    updateDoc,
    limit,
    serverTimestamp,
    getDoc,
} from "firebase/firestore";
import ansScreenShot from '../assets/code.png';

export const TestResultDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const auth = getAuth();
    const [userDetails, setUserDetails] = useState(null);
    const [quesList, setQuesList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [answerScreen, setAnswerScreen] = useState(null);
    const [additionQues, setAdditionalQues] = useState([]);
    const [sheelaFirstQues, setSheelaFirstQues] = useState("");
    const [sheelaSecondQues, setSheelaSecQues] = useState("");
    const [documentData, setDocumentData] = useState(null);
    const [compileAttemp, setCompileAttempt] = useState([]);
    const [resizeScreens, setResizeScreen] = useState([]);
    const [selectedCandidate, setCandidate] = useState("");

    const { user, userInfoLoaded, currentUserVal } = UserAuth();

    // For Video Fullscreen Model 
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // For Nav & Tabs For Details
    const [key, setKey] = useState('home');

    const detail = location.state;
    console.log("Test Result Details");
    console.log(detail);

    let startMoment = moment(detail?.test_starttime).utc();
    let endMoment = moment(detail?.test_endtime).utc();
    let duration = moment.duration(endMoment.diff(startMoment));

    const formattedDate = startMoment.format('YYYY-MM-DD HH:mm:ss');

    // Fetch Test Questions and Reason
    const fetchAdditionQues = async (ques_id, test_id, question_bank_id) => {
        setIsLoading(true);
        try {
            const refreshedToken = await currentUserVal?.getIdToken(true);
            var data = JSON.stringify({
                "ques_id": ques_id,
                "test_id": test_id,
                "question_bank_id": question_bank_id
            });
            var headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.post(`${process.env.REACT_APP_TESTSERVER}get-additional-answer`, data, { headers });
            let testDatares = response.data;
            console.log("response Details of get-additional");
            console.log(testDatares);
            let dataRes = testDatares.data?.length > 0 ? testDatares.data : [];
            let attempts = testDatares.attempt?.length > 0 ? testDatares.attempt : [];
            setCompileAttempt(attempts);
            let screenshots = testDatares.exitScreenshots?.length > 0 ? testDatares.exitScreenshots : [];
            setResizeScreen(screenshots);
            dataRes?.length > 0 && dataRes?.map((element, index) => {
                if (index == 0)
                    setSheelaFirstQues(element?.result);
                if (index == 1)
                    setSheelaSecQues(element?.result);
            })
            setAdditionalQues(dataRes);
            setIsLoading(false);
        } catch (error) {
            // console.log(error);
            setIsLoading(false);
        }
    }




    useEffect(() => {
        setUserDetails(detail);
        console.log("Test Result Details Page ****");
        console.log(detail);
        if (detail?.test_id) {
            let date = new Date(detail?.ques_time);
            let year = date.getUTCFullYear();
            let month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Add 1 to month because it's 0-indexed
            let day = String(date.getUTCDate()).padStart(2, '0');

            // Format the date as "yyyy-mm-dd"
            const formattedDate = `${year}-${month}-${day}`;
            console.log("foormated Date");
            console.log(formattedDate);

            let OldscreenValue = `https://s3.amazonaws.com/test.virtualemployee.com/${detail?.user_id}/${formattedDate}/${detail?.test_id}/${detail?.id}.png`;
            let ansScreenShot = (detail?.screenshot_url === null || detail?.screenshot_url === undefined || detail?.screenshot_url === "") ? OldscreenValue : detail?.screenshot_url;


            // let ansScreenShot = `https://s3.amazonaws.com/test.virtualemployee.com/${detail?.user_id}/${formattedDate}/${detail?.test_id}/${detail?.id}.png`
              setAnswerScreen(ansScreenShot);

            fetchAdditionQues(detail?.id, detail?.test_id, detail?.ques_bank_id)
        }

    }, [detail])

    const handleClickCode = async (candidateInfo) => {
        const encryptedQuery = CryptoJS.AES.encrypt(JSON.stringify(candidateInfo), 'secret_key').toString();
        navigate(`/compile-code?query=${encodeURIComponent(encryptedQuery)}`);
    }

    return (
        <div className='test-result-page py-3'>
            <div className='container-fluid'>
                <div className="fs-18 fw-medium mb-2">Test Result Details ---</div>
                <div className='ques-ans-item card rounded-3 p-3'>
                    <div className='question-wrapper fs-14'>
                        <div className='info-box'>
                            <div className='title-label fs-6 fw-semibold mb-1'>Question:</div>
                            <div className='question text-secondary mb-2'>{detail?.question}</div>
                            {detail?.example ? <div>
                                <div className='title-label text-secondary'>Example:</div>
                                <div className='example text-secondary'>{detail?.example}</div>
                            </div> : null}
                        </div>
                    </div>

                    <div className='row g-3 ques-timing fs-14 mt-2'>
                        <div className='col-md-6 col-xl-4'>
                            <div className='info-box'>
                                <div className='label-name'>Visible to Candidate on:</div>
                                <div className='label-value fs-6 fw-semibold text-uppercase text-nowrap'>06:43 PM</div>
                            </div>
                        </div>
                        <div className='col-md-6 col-xl-4'>
                            <div className='info-box'>
                                <div className='label-name'>Candidate Submitted the Solution At:</div>
                                <div className='label-value fs-6 fw-semibold text-uppercase text-nowrap'>07:03 PM</div>
                            </div>
                        </div>
                        <div className='col-md-6 col-xl-4'>
                            <div className='info-box'>
                                <div className='label-name'>Total Time Taken by Candidate to Complete the Solution:</div>
                                <div className='label-value fs-6 fw-semibold text-nowrap'>19 mins</div>
                            </div>
                        </div>
                    </div>

                    <div className="accordion-wrapper mt-4">
                        <Accordion>
                            <Accordion.Item className='overflow-hidden' eventKey="1">
                                <Accordion.Header bsPrefix="custom-acrdn-btn mb-0">Suspected Times Intervals and Screenshots Indicating Candidates Exit from the Interview Software: Please Review These Sections Carefully.</Accordion.Header>
                                <Accordion.Body>
                                    <div className="alertinfo bg-warning bg-opacity-75 fs-14 mb-3 p-2">
                                        It is recommended to review the video recording of the solution starting from 1 minute before and after the timestamp to gain a better understanding of the candidate's activity.
                                    </div>
                                    {documentData?.compositionSid && documentData?.compositionPercentageDone == 100 ? <div className='videoWrapper'>
                                        <div className="fs-18 fw-medium text-capitalize mb-2"><i className="bi bi-film small text-primary"></i> Interview video recording -</div>
                                        <div className="zoom-wrapper ratio ratio-16x9 overflow-hidden" style={{ maxWidth: '500px' }}>
                                            <video id="videoSrc" className='border rounded w-100 d-block object-fit-cover' height="240" controls></video>
                                        </div>

                                        {documentData?.compositionPercentageDone ? <div className='text-danger fw-medium mt-2'>Completed composition percentage - <span className='text-success fw-semibold'>{`${documentData?.compositionPercentageDone}%`}</span></div> : null}
                                    </div>
                                        : null}
                                    <div className="row g-3 g-xl-4">
                                        {resizeScreens?.length > 0 && resizeScreens?.map((elementInArray, index) => (
                                            <div className="col-sm-6 col-xl-4">
                                                <div className="individual-ques-info text-center">
                                                    <div className="img-wrapper">
                                                        <ModalImage className="img-fluid" showRotate
                                                            small={elementInArray?.sendscreenshot_url}
                                                            large={elementInArray?.sendscreenshot_url}
                                                            alt="Test Result Details"
                                                        />
                                                    </div>
                                                    <div className="info-box fs-14 p-3">
                                                        <div className="total-time fw-medium text-primary mb-1">{elementInArray?.resizing_time}</div>
                                                        <div className="description">Click on This Timestamp to View the Video Starting from This Point.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>

                    <div className='row g-3 mt-3'>
                        <div className='col-xl-3 col-sm-6'>
                            <div className='info-box text-uppercase text-center'>
                                <div className='label-name fs-5 fw-medium text-primary bg-primary bg-opacity-10 mb-2 mb-lg-3 p-4'>Technical<br /> Question</div>
                                <div className={`label-value text-white fs-18 p-2 p-xl-3 ${detail.result === 'Pass' ? 'bg-success' : 'bg-warning' && detail.result === 'Fail' ? 'bg-danger' : 'bg-warning'}`}>{detail?.result}</div>
                            </div>
                        </div>
                        {detail.result === 'Pass' ?
                            <>
                                {sheelaFirstQues != "" ? <div className='col-xl-3 col-sm-6'>
                                    <div className='info-box text-uppercase text-center'>
                                        <div className='label-name fs-5 fw-medium text-primary bg-primary bg-opacity-10 mb-2 mb-lg-3 p-4'>Sheela's 1st<br /> Question</div>
                                        <div className={`label-value text-white fs-18 p-2 p-xl-3 ${sheelaFirstQues === 'Pass' ? 'bg-success' : 'bg-warning' && sheelaFirstQues === 'Fail' ? 'bg-danger' : 'bg-warning'}`}>{sheelaFirstQues}</div>
                                    </div>
                                </div> : null}

                                {sheelaSecondQues != "" ? <div className='col-xl-3 col-sm-6'>
                                    <div className='info-box text-uppercase text-center'>
                                        <div className='label-name fs-5 fw-medium text-primary bg-primary bg-opacity-10 mb-2 mb-lg-3 p-4'>Sheela's 2nd<br /> Question</div>
                                        <div className={`label-value text-white fs-18 p-2 p-xl-3 ${sheelaSecondQues === 'Pass' ? 'bg-success' : 'bg-warning' && sheelaSecondQues === 'Fail' ? 'bg-danger' : 'bg-warning'}`}>{sheelaSecondQues}</div>
                                    </div>
                                </div> : null}

                            </> : null}
                        <div className='col-xl-3 col-sm-6'>
                            <div className='info-box text-uppercase text-center'>
                                <div className='label-name fs-5 fw-medium text-primary bg-primary bg-opacity-25 mb-2 mb-lg-3 p-4'>Overall<br /> Result</div>
                                {(sheelaFirstQues != "" || sheelaSecondQues != "") ?
                                    <div className={`label-value text-white fs-18 p-2 p-xl-3 ${(sheelaFirstQues === 'Pass' || sheelaSecondQues === 'Pass') ? 'bg-success' : 'bg-warning' && (sheelaFirstQues != 'Pass' || sheelaSecondQues != 'Pass') ? 'bg-danger' : 'bg-warning'}`}>{(sheelaFirstQues === 'Pass' || sheelaSecondQues === 'Pass') ? "Pass" : "Fail"}</div> :
                                    <div className={`label-value text-white fs-18 p-2 p-xl-3 ${detail.result === 'Pass' ? 'bg-success' : 'bg-warning' && detail.result === 'Fail' ? 'bg-danger' : 'bg-warning'}`}>{detail?.result}</div>}





                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-6">
                            <div className="submitted-solution">
                                <div className="fs-6 fw-semibold mb-2">Submitted Solution:</div>
                                <div className='submitted-img zoom-wrapper mt-2'>
                                    <ModalImage className="img-fluid" showRotate
                                        small={answerScreen}
                                        large={answerScreen}
                                        alt="Test Details"
                                    />
                                </div>
                                {detail?.code != null ? <button type='button' className='btn btn-sm btn-primary px-3 lh-1 mt-3' onClick={() => handleClickCode(detail)}>Check Code</button> : ""}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className='info-box d-flex gap-2 mt-3 mt-md-0'>
                                <div className='label-name fw-medium'>Result:</div>
                                <div className={`label-value fw-semibold text-uppercase ${detail.result === 'Pass' ? 'text-success' : 'text-warning' && detail.result === 'Fail' ? 'text-danger' : 'text-warning'}`}>{detail?.result}</div>
                            </div>

                            {detail.result === 'Pass' && (
                                <div className="passinfo bg-warning bg-opacity-75 fs-14 mt-2 p-2">
                                    If the result is pass, Sheela asked the candidate cross questions based on their submitted solution code. Scroll down the page to view all related questions and their answers.
                                </div>
                            )}
                            {detail.result !== 'Skip' ?
                            <div className='info-box fs-14 mt-3'>
                                <div className='label-name fw-medium mb-1'>Reason Why the Solution is {detail.result === 'Pass' ?'Correct':'Incorrect'}:</div>
                                <div className='label-value text-secondary'>{detail.reason}</div>
                            </div>
                            :''}
                        </div>
                    </div>

                    <hr className='border-3 my-4' />

                    {detail.result === 'Pass' && additionQues?.length > 0 &&
                        <div className='additional_QA'>
                            <div className='fs-6 fw-semibold mb-2'>Additional Q&A List:</div>
                            <Accordion>
                                {additionQues?.length > 0 && additionQues?.map((data, index) => {
                                    return (
                                        <Accordion.Item className='overflow-hidden' eventKey={index}>
                                            <Accordion.Header bsPrefix="custom-acrdn-btn mb-0">
                                                <div className='d-flex flex-column align-items-start flex-md-row gap-md-2'>
                                                    <div className='title-label d-flex flex-wrap align-items-start gap-1 fw-medium mb-2 mb-md-0'>
                                                        <div className='fw-medium'>Question {index + 1}:</div>
                                                        <div className={`badge text-uppercase ${data.result === 'Pass' ? 'text-bg-success' : 'text-bg-danger'}`}>{data.result}</div>
                                                    </div>
                                                    <div className='question'>{data?.releatedQues}</div>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body className='p-3'>
                                                <div className='d-flex flex-column flex-md-row gap-md-2 mb-2'>
                                                    <div className='title-label fw-medium'>Answer:</div>
                                                    <div className='question text-body'>{data?.answer}</div>
                                                </div>
                                                <div className='d-flex flex-column flex-md-row gap-md-2'>
                                                    <div className='title-label fw-medium'>Reason:</div>
                                                    <div className='question text-muted'>{data?.reason}</div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })}

                            </Accordion>
                        </div>
                    }

                    <hr className='border-3 my-4' />

                    <div className='timestamps-section'>
                        <div className='label-name fs-6 fw-semibold mb-1'>Code Compilation Status with Timestamps.</div>
                        <div className='label-value fs-14 text-secondary'>This section indicates the number of times the candidate attempted to compile the code, the errors encountered if rejected and their corresponding timestamps.</div>
                        <div className="table-responsive mt-3">
                            <table className='table table-bordered border-secondary border-opacity-50 mb-0'>
                                <thead>
                                    <tr>
                                        <th scope='column' className='text-uppercase'>Timestamps</th>
                                        <th scope='column' className='text-uppercase'>Status</th>
                                        <th scope='column' className='text-uppercase'>Response</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {compileAttemp?.length > 0 && compileAttemp?.map((compile) => {
                                        return (<tr>
                                            <td>
                                                <div className='time text-uppercase'>{moment(compile?.compile_time).format('YYYY-MM-DD HH:mm:ss a')}</div>
                                            </td>
                                            <td>
                                                <div className='ans-status text-danger text-uppercase'>{compile?.status}</div>
                                            </td>
                                            <td>
                                                <div className="error text-danger">{compile?.response}</div>
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
