import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth } from '../firebaseConfig';
import { UserAuth } from '../context/AuthContext';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import '../common/custom.css'
import { Loader } from '../components/loader';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { useMyContext } from '../context/MyContext';
import CryptoJS from 'crypto-js';
import { useNavigationContext } from "../context/NavContext";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import searchIcon from '../assets/search.svg'
import moment from 'moment';
// pagination
import Pagination from '../components/Pagination';
// pagination

export const RecruitmentList = () => {
	const { activepage, handlePageClick } = useNavigationContext()
	// stages
	const navigate = useNavigate();
	const auth = getAuth();
	const { user, userInfoLoaded, currentUserVal } = UserAuth();
	const { values, handleChange } = useMyContext()
	console.log("****************Values************");
	console.log(values);
	const [selectedItem, setSelectedItem] = useState(null);
	const [items, setItems] = useState([]);
	const [itemsview, setItemsView] = useState([]);
	const [userData, setUsers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingdata, setIsLoadingData] = useState(true);
	const [hrwokingdata, setHrworkingdata] = useState('')
	const [outsourcedHR, setOutsourcedHR] = useState('')
	const [myrequistion, setMyrequisition] = useState([])
	const [countInhousedata, setCountInhousedata] = useState([])
	const [countOutsourced, setCountOutsourced] = useState([])
	const [jobstatus, setJobstatus] = useState(false)
	const [midCntr, setMidCntr] = useState(false)
	const [statusdata, setStatusdata] = useState([])
	const [alertshow, setAlertShow] = useState(false)
	const [alertmsg, setAlertMsg] = useState('')
	const [isActive, setIsActive] = useState(false)
	const [isAccepted, setisAccept] = useState(false)
	const [isHR, setisHR] = useState(false)
	const [selectedOption, setSelectedOption] = useState('')
	const [pagedata, setPagedata] = useState('')
	const [deptData, setdeptData] = useState([])
	const [expData, setexpData] = useState([])
	const [empData, setempData] = useState([])
	const [jobnatureData, setJobnatureData] = useState([])
	const [shifttypeData, setShifttypeData] = useState([])
	const [reqbyData, setReqbyData] = useState([])
	const [selectedDeptoption, setSelectedDeptoption] = useState(values.department);
	const [selectedEmpoption, setSelectedEmpoption] = useState(values.emptype);
	const [selectedExpoption, setSelectedExpoption] = useState(values.exp);
	const [selectedReqoption, setSelectedReqoption] = useState(values.requestedBy);
	const [shifttypeoption, setShifttypeoption] = useState(values.shift);
	const [jobtypeoption, setJobtypeoption] = useState(values.jobnature);
	const [hrtypedata, setHrtypedata] = useState(values.hrtype)
	const [outerData, setOuterdata] = useState('');
	const [countRequisition, setCountRequisition] = useState('')
	const [currentjobstatus, setCurrentjobstatus] = useState(values.currentjobstatus);
	// stages
	const [reqId, setReqId] = useState('')
	const [show, setShow] = useState(false)
	const [innershow, setInnershow] = useState(false)
	const [statusValue, setStatusValue] = useState('')
	const [dynamicstatus, setDynamicstatus] = useState([])
	const [formData, setFormData] = useState({
		status: '',
		comment: '',
	});
	const [debouncedSearchVal, setDebouncedSearchVal] = useState("");
	const [searchVal, setSearchVal] = useState("");



	// pagination
	const [currentPage, setCurrentPage] = useState(1)
	const [totalPages, setTotalPages] = useState(1)
	const itemsPerPage = 10;

	// DateSelection
	const [setResponsedata, setResponse] = useState('')
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [DateError, setDateError] = useState("");

	useEffect(() => {
		fetchUsertype();
		filterList();
	}, [])

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedSearchVal(searchVal);
		}, 500); // Adjust debounce delay as needed
		return () => {
			clearTimeout(handler);
		};
	}, [searchVal]);

	useEffect(() => {

		fetchrequisitionsList();
	}, [selectedEmpoption,
		selectedExpoption,
		selectedDeptoption,
		shifttypeoption,
		jobtypeoption,
		selectedReqoption,
		hrtypedata,
		currentjobstatus,
		debouncedSearchVal,
		startDate,
		endDate,
		currentPage,
		itemsPerPage]);

	const resetDateFilters = () => {
		setStartDate('');
		setEndDate('');
		handleChange('selectStartDate', "");
		handleChange('selectEndDate', "");
	};

	const handleStartDate = async (dateData) => {
		const startDateValue = dateData;
		const endDateValue = endDate;
		if (startDateValue === "") {
			setStartDate("");
			setEndDate("");
			return;
		}
		if (endDateValue && startDateValue > endDateValue) {
			setDateError("Start date cannot be after the end date");
			return;
		}
		else {
			setStartDate(startDateValue);
			setDateError("");
		}
	};
	const handleEndDate = async (dateData) => {
		const endDateValue = dateData;
		const startDateValue = startDate; // Get the current start date value
		if (endDateValue === "") {
			setEndDate("");
			setStartDate("");
			return;
		}
		if (startDateValue && endDateValue < startDateValue) {
			setDateError("End date cannot be before the start date");
			return;
		}
		else {
			setEndDate(endDateValue);
			setDateError("");
		}
	};
	// DateSelection

	// Handle job filter in mobile device
	const [isAdded, setIsAdded] = useState(false);
	const handleToggle = () => {
		setIsAdded(!isAdded);
	};

	// get recruitment list
	const fetchUsertype = () => {
		const userType = user?.userType;
		if (userType == 'HR') {
			setisHR(true)
		} else {
			setisHR(false)
		}
	}
	// getFilterOption
	const filterList = async () => {
		const refreshedToken = await currentUserVal?.getIdToken(true);
		const dataget = {
			"pageSize": "itemsPerPage"
		}
		var headers = {
			'Authorization': `Bearer ${refreshedToken}`,
			'Content-Type': 'application/json'
		};
		axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/filter-options`, dataget, { headers })
			.then(function (response) {
				let dashDatares = response.data;
				let data = dashDatares.data ? dashDatares.data : {};
				var departmentData = dashDatares?.department
				var experienceData = dashDatares?.experience
				var employmentData = dashDatares?.employementType
				var jobnatureData = dashDatares?.jobNatureType
				var shiftData = dashDatares?.shiftType
				var requestedData = dashDatares?.requestedBy
				setdeptData(departmentData)
				setexpData(experienceData)
				setempData(employmentData)
				setJobnatureData(jobnatureData)
				setShifttypeData(shiftData)
				setReqbyData(requestedData)
			}).catch(function (error) {
				setIsLoading(false);
			});
	}


	const optionChange = (e, value2) => {

		console.log(value2);
		setCurrentPage(1);

		// Extract value from the event
		const selectedValue = e.target.value;
		// Update context values
		handleChange(value2, selectedValue);

		switch (value2) {
			case 'emptype':
				setSelectedEmpoption(selectedValue);
				break;
			case 'exp':
				setSelectedExpoption(selectedValue);
				break;
			case 'department':
				setSelectedDeptoption(selectedValue);
				break;
			case 'shift':
				setShifttypeoption(selectedValue);
				break;
			case 'jobnature':
				setJobtypeoption(selectedValue);
				break;
			case 'requestedBy':
				setSelectedReqoption(selectedValue);
				break;
			case 'hrtype':
				setHrtypedata(selectedValue);
				break;
			case 'currentjobstatus':
				setCurrentjobstatus(selectedValue);
				break;
			case 'searchData':
				setSearchVal(selectedValue);
				break;
			case 'selectStartDate':
				handleStartDate(selectedValue);
				setStartDate(selectedValue);
				break;
			case 'selectEndDate':
				handleEndDate(selectedValue);
				setEndDate(selectedValue);
				break;
			default:
				break;
		}
	};


	const fetchrequisitionsList = async () => {
		setIsLoading(true);
		const refreshedToken = await currentUserVal?.getIdToken(true);

		const postData = {
			department: selectedDeptoption || '',
			minimumExp: selectedExpoption || '',
			employmentType: selectedEmpoption || '',
			requestedBy: selectedReqoption || '',
			shiftType: shifttypeoption || '',
			jobNatureType: jobtypeoption || '',
			statusType: currentjobstatus || '',
			fromDate: startDate || '',
			toDate: endDate || '',
			searchString: debouncedSearchVal.replace('VEREQ', ''),
			is_searchByRequisition: debouncedSearchVal.includes('VEREQ') ? 1 : 0,
			page: currentPage,
			pageSize: itemsPerPage
		};
		if (user?.userType == "MGR") {
			postData.userType = user?.userType;
			postData.managerEmail = user?.email;
			postData.requestedBy= user?.email;
		}
		makeCountRequest(postData);

		const headers = {
			'Authorization': `Bearer ${refreshedToken}`,
			'Content-Type': 'application/json',
		};

		try {
			const response = await axios.post(
				`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/get-requisitions?page=${currentPage}&pageSize=${itemsPerPage}`,
				postData,
				{ headers }
			);

			const userDatares = response.data;
			const requisitionsList = userDatares.requisitions;

			if (requisitionsList.length >= 1) {
				const info = requisitionsList[0];
				const index = 0;
				handleItemClick(info, index);
				setIsLoadingData(false);
			} else {
				//setJobstatus(!getValue);
				setMidCntr(true);
				setPagedata('No data to show.');
				setItemsView(null);
				setIsLoadingData(false);
			}

			setItems(requisitionsList);
			const data = userDatares.data?.length > 0 ? userDatares.data : [];
			setUsers(data);

			const totalPages = Math.ceil(data.length / itemsPerPage);
			setTotalPages(totalPages);
			setTotalPages(userDatares?.totalPages);
			setCountRequisition(userDatares?.totalCount);

		} catch (error) {
			console.log(error);
			setIsLoading(false);
			setIsLoadingData(false);
		}
	};



	// useEffect(() => {
	// 	getStatusOptions()
	// 	fetchUsertype()
	// 	if (auth)
	// 		// fetchrequisitionsList();
	// 		filterList();
	// 	// onloadFilter()
	// 	if (((values.empOption === '') || (values.empOption == undefined) || (values.empOption == null)) && ((values.expOption === '') || (values.expOption == undefined) || (values.expOption == null)) && ((values.deptOption === '') || (values.deptOption == undefined) || (values.deptOption == null)) && ((values.shiftOption === '') || (values.shiftOption == undefined) || (values.shiftOption == null)) && ((values.jobnatureOption === '') || (values.jobnatureOption == undefined) || (values.jobnatureOption == null)) && ((values.requestedByOption === '') || (values.requestedByOption == undefined) || (values.requestedByOption == null)) && ((values.currentjobstatustypeOption === '') || (values.currentjobstatustypeOption == undefined) || (values.currentjobstatustypeOption == null)) && ((values.setsearchValue === '') || (values.setsearchValue == undefined) || (values.setsearchValue == null)) && ((values.selectStarttypeOption === '') || (values.selectStarttypeOption == undefined) || (values.selectStarttypeOption == null)) && ((values.selectEndtypeOption === '') || (values.selectEndtypeOption == undefined) || (values.selectEndtypeOption == null))) {
	// 		// fetchrequisitionsList();
	// 		var value2 = ''
	// 		var getValueData = ''
	// 		fetchrequisitionsList(getValueData, value2);
	// 	} else {
	// 		onloadFilter()
	// 	}
	// }, [auth, currentPage, startDate, endDate])
	// get recruitment list


	const handleItemClick = async (info, index) => {
		formData.comment = ""
		fetchUsertype()
		setIsActive(!isActive)
		// console.log(info.id)
		const getrequisitionId = info.id
		setIsLoadingData(true);
		const refreshedToken = await currentUserVal?.getIdToken(true);
		// console.log(refreshedToken)
		const postData = {
			requisitionId: getrequisitionId
		};
		var headers = {
			'Authorization': `Bearer ${refreshedToken}`,
			'Content-Type': 'application/json'
		};
		axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/requisition-details`, postData, { headers })
			.then(function (response) {
				var userDatares = response.data;
				// console.log("Detail of Requisition");
				// console.log(userDatares);
				const getJobdetails = userDatares.jobDetails
				setOuterdata(getJobdetails ? getJobdetails : '')
				const requisitionsView = getJobdetails.requisition
				//   const hrworking = getJobdetails.otherHrWorking
				const getrequisitiondata = requisitionsView?.my_requisitions
				const hrworking = getJobdetails?.otherInhouseHrWorking
				// console.log("Hrworking Data:",hrworking)
				const outsourcedHRdata = getJobdetails?.otherOuthouseHrWorking
				const countInhouse = hrworking != '' ? hrworking.trim().split(',').length : 'No'
				const countOutsourcedData = outsourcedHRdata != '' ? outsourcedHRdata.trim().split(',').length : 'No'
				setCountInhousedata(countInhouse)
				setCountOutsourced(countOutsourcedData)
				setOutsourcedHR(outsourcedHRdata)
				setHrworkingdata(hrworking)
				setItemsView(requisitionsView);
				setMyrequisition(getrequisitiondata)
				getStatusOptions()
				console.log('requisitionsView');
				
				// console.log(requisitionsView)
				// console.log('requisitionsView');
				if (requisitionsView?.my_requisitions?.length > 0) {
					const emailToFilter = user?.email;
					const filteredData = requisitionsView?.my_requisitions?.filter(item => item.email === emailToFilter);
					if (filteredData?.length > 0)
						setisAccept(true);
					else
						setisAccept(false);
				}
				else {
					setisAccept(false);
				}


				// console.log(requisitionsView)
				var requisitionsStatus = userDatares.requisitionStatus
				if (requisitionsStatus?.length == 0) {
					setJobstatus(true)
					setMidCntr(true)
				} else {
					setStatusdata(requisitionsStatus)
				}
				let data = userDatares.data?.length > 0 ? userDatares.data : [];
				setUsers(data);
				// console.log(itemsview)
				setIsLoading(false);
				setIsLoadingData(false);
				// Calculate the total number of pages based on the fetched data and items per page
			})
			.catch(function (error) {
				// console.log(error);
				setIsLoading(false);
				setIsLoadingData(false);
			});
		// }
		// setSelectedItem(index === selectedItem ? 0 : index);
		setSelectedItem(index === selectedItem ? (selectedItem === null ? 0 : selectedItem) : index);
	};

	const acceptJob = async (e) => {
		const getrequisitionId = e.id
		const userName = user.displayName
		const userEmail = user.email
		const postData = {
			requisitionId: getrequisitionId,
			name: userName,
			email: userEmail,
			userId: user?.userId
		};
		const refreshedToken = await currentUserVal?.getIdToken(true);
		var headers = {
			'Authorization': `Bearer ${refreshedToken}`,
			'Content-Type': 'application/json'
		};
		axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/accept-requisition`, postData, { headers })
			.then(function (response) {
				var userDatares = response.data;
				if (userDatares.statusCode == 200) {
					alertFunc('Requisition has been accepted');

					handleItemClick(e, selectedItem);
				}
			})
			.catch(function (error) {
				// console.log(error);
				alertFunc('Requisition has been already accepted');
				handleItemClick(e, selectedItem)
				setIsLoading(false);
			});
	}

	const alertFunc = async (msg) => {
		setAlertMsg(msg)
		setAlertShow(true)
		setTimeout(() => {
			setAlertShow(false)
		}, 6000)
	}
	function formatDate(requiredBy) {
		const today = new Date();
		const requiredDate = new Date(requiredBy);
	
		const yearDifference = requiredDate.getFullYear() - today.getFullYear();
		const monthDifference = (requiredDate.getMonth() - today.getMonth()) + (yearDifference * 12);
		const differenceInMs = requiredDate - today;
		const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
	
		if (differenceInDays < 0) {
			// The date is in the past
			return formatDifference(Math.abs(differenceInDays), Math.abs(monthDifference), Math.abs(yearDifference)) + ' ago';
		} else if (differenceInDays > 0) {
			// The date is in the future
			return formatDifference(differenceInDays, monthDifference, yearDifference) + ' remaining';
		} else {
			// The date is today
			return "Today";
		}
	}
	function formatDifference(days, months, years) {
		// Handle the case where days can contribute to months and months to years
		const effectiveYears = Math.floor(days / 365); // Approximate 1 year as 365 days
		const remainingDaysAfterYears = days % 365;
	
		const effectiveMonths = Math.floor(remainingDaysAfterYears / 30); // Approximate 1 month as 30 days
		const remainingDaysAfterMonths = remainingDaysAfterYears % 30;
	
		if (years > 0 || effectiveYears > 0) {
			if (effectiveYears > 0) {
				return `${effectiveYears} years`;
			} else {
				return `${years} years`;
			}
		} else if (months > 0 || effectiveMonths > 0) {
			if (effectiveMonths > 0) {
				return `${effectiveMonths} months`;
			} else {
				return `${months} months`;
			}
		} else {
			return `${days} days`;
		}
	}
	
	const Myrequisition = (info) => {
		if(user.userType === 'HR'){
			handlePageClick('my-recruitment-list')
		}else{
			handlePageClick('recruitment-list')
		}
		// let requisitionId = info?.id;
		let requisitionInfo = {
			requisitionId : info?.id,
			requisition : info,
			otherHR: info.my_requisitions.filter(hruser => hruser.email !== user.email),
			user: info.my_requisitions.filter(hruser => hruser.email == user.email),
			timeAgo:outerData.time_age ? outerData.time_age : '',
			time_remaining:info?.reqiredBy?formatDate(info.reqiredBy):''
		} 
		//setOutsourcedHR(outsourcedHRdata)
		//setHrworkingdata(hrworking)
		console.log(requisitionInfo);
		const encryptedQuery = CryptoJS.AES.encrypt(JSON.stringify(requisitionInfo), 'secret_key').toString();
		navigate(`/my-requisition-details?query=${encodeURIComponent(encryptedQuery)}`);
	}

	// buttonClick
	const handleClick = (type) => {
		if (type === 'prev' && currentPage > 1) {
			setCurrentPage(currentPage - 1);
		} else if (type === 'next' && currentPage < totalPages) {
			// setCurrentPage(currentPage + 1)
			setCurrentPage(18)
		}
	}
	// buttonClick

	// modalClose
	const handleClose = () => {
		console.log("handleClose")
	}
	// modalClose

	// getOption
	const getOption = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	}
	// getOption

	// changeStatus
	const changeStatus = (e, retrieveValue) => {
		e.preventDefault()
		setShow(true)
		setReqId(retrieveValue?.id)
		console.log(reqId)
	}
	// changeStatus

	// submitValue
	const submitValue = async (getwork, optiondata, getreqId, itemsStatus) => {
		if (formData.status == '') {
			formData.status = itemsStatus == 'Closed' || itemsStatus == 'Open' || itemsStatus == 'Hold' ? itemsStatus : ''
		}
		console.log(itemsStatus)
		if (getwork == "passValue") {
			if (formData.status == '' || formData.comment == '') {
				toast.error('Please select status and give comment.', {
					position: toast.POSITION.BOTTOM_CENTER
				});
			} else {
				setInnershow(true)
				setStatusValue(formData.status)
				setShow(false)
			}
		}
		else if (getwork == "finalSubmit") {
			setInnershow(false)
			setShow(false)
			// apisWork
			const refreshedToken = await currentUserVal?.getIdToken(true);
			const postData = {
				"requisitionId": getreqId,
				"addedBy": user.displayName,
				"addedByEmail": user.email,
				"status": formData.status,
				"note": formData.comment
			}
			console.log(postData)
			var headers = {
				'Authorization': `Bearer ${refreshedToken}`,
				'Content-Type': 'application/json'
			};
			const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/changeRequistionStatus`, postData, { headers })
			const responseData = response?.data
			if (responseData?.statusCode == 200) {
				toast.success('Status has been updated.', {
					position: toast.POSITION.BOTTOM_CENTER
				});
			}
			// fetchrequisitionsList()
			const info = {
				"id": getreqId
			}
			const index = selectedItem
			handleItemClick(info, index)
			getStatusOptions()
			formData.comment = ""
			console.log(responseData)
			// apisWork
		}
		console.log(optiondata)
		console.log(formData.status)
	}
	// submitValue

	// closeModal
	const closeModal = (getValue) => {
		console.log(getValue)
		if (getValue == 'outerModal') {
			setShow(false)
		} else if (getValue == 'innerModal') {
			setInnershow(false)
			setShow(true)
		}
	}
	// closeModal

	// handleSubmit
	const handleSubmit = (e) => {
		e.preventDefault();
		console.log('Submitted Data:', formData);
		// Here you can further process the form data, send it to an API, or perform any required actions
	};
	// handleSubmit

	// getStatusOptions
	const getStatusOptions = async () => {
		const refreshedToken = await currentUserVal?.getIdToken(true);
		var headers = {
			'Authorization': `Bearer ${refreshedToken}`,
			'Content-Type': 'application/json'
		};
		console.log(headers)
		const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/requistionStatusList`, {}, { headers })
		const responseData = response?.data
		setDynamicstatus(responseData?.list)
	}
	// getStatusOptions

	// pagination
	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const searchFunction = (event) => {
		setCurrentPage(1);
		let searchText = event.target.value;
		const getremovedVal = searchText.replace('VEREQ', '');
		setSearchVal(searchText);
	}

	const makeCountRequest = async (getData) => {
		const refreshedToken = await currentUserVal?.getIdToken(true);
		var headersdata = {
			'Authorization': `Bearer ${refreshedToken}`,
			'Content-Type': 'application/json'
		};
		try {
			const res = await fetch(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/get-count`, {
				method: 'POST',
				headers: headersdata,
				body: JSON.stringify(getData)
			});
			if (!res.ok) {
				throw new Error('Network response was not ok');
			}
			const data = await res.json();
			setResponse(data);
		} catch (error) {
			console.log(error.message);
		}
	};

	// HtmlConversion
	const HtmlToTextComponent = ({ htmlString }) => {
		return (
			<div>
				{/* Using a temporary div to convert HTML to text */}
				{/* <div
					dangerouslySetInnerHTML={{ __html: htmlString }}
					style={{ display: 'none' }} // Hide the original HTML
				/> */}
				<div dangerouslySetInnerHTML={{ __html: htmlString }} />
				<div>
					{new DOMParser().parseFromString(htmlString, 'text/html').body.textContent}
				</div>
			</div>
		);
	};
	// HtmlConversion

	return (
		<div className='recruitment-list-page py-3'>
			{/* alert */}
			<Alert variant="success" show={alertshow} dismissible className="alertMsg">
				<Alert.Heading>{alertmsg}</Alert.Heading>
			</Alert>
			{/* alert */}

			<div className='container-fluid'>
				{/* <div className="d-flex flex-wrap justify-content-between gap-2 mb-1 lh-sm">
					<div className='fs-3 fw-semibold'>Jobs Recruitment</div>
					<div className='fs-4 fw-medium'>Total Requisition: <span className="text-primary fw-bold">{countRequisition}</span></div>
				</div> */}

				<div className='d-flex flex-column flex-md-row flex-wrap align-items-md-center justify-content-between gap-2 mb-2'>
					<div className='left-sec'>
						<div className='fs-3 fw-semibold lh-sm'>Jobs Recruitment</div>
					</div>

					<div className="right-sec">
						<div className="date-filter d-flex flex-column flex-sm-row flex-wrap align-items-sm-center gap-2">
							<div className="start_date d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2">
								<label htmlFor="startDate">From</label>
								<input type="date" className="form-control h-auto bg-white bg-opacity-50 border-0 rounded-3 shadow-none px-3 py-2" id="startDate"
									// min={new Date().toJSON().slice(0, 10)}
									// defaultValue={new Date().toJSON().slice(0, 10)}
									value={startDate}
									onChange={(e) => optionChange(e, 'selectStartDate')} required
								/>
							</div>
							<div className="end_date d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2">
								<label htmlFor="startDate">To</label>
								<input type="date" className="form-control h-auto bg-white bg-opacity-50 border-0 rounded-3 shadow-none px-3 py-2" id="endDate"
									// min={new Date().toJSON().slice(0, 10)}
									// defaultValue={new Date().toJSON().slice(0, 10)}
									value={endDate}
									onChange={(e) => optionChange(e, 'selectEndDate')} required
								/>
							</div>
							<div className="btn-wrapper d-flex flex-column gap-1 gap-md-2 text-center">
								<label className="d-none d-sm-block d-md-none">&nbsp;</label>
								{/* <div className="bg-white bg-opacity-50 border-0 rounded-3 shadow-none px-3 py-2">
									<i className="bi bi-search"></i> <span className="fw-medium d-sm-none">Search</span>
								</div> */}
								<button type='button' className='btn btn-sm btn-primary px-3 lh-1' onClick={() => resetDateFilters()}>Reset</button>
							</div>
						</div>
						{DateError && <div className="small text-danger text-md-end mt-1">{DateError}</div>}
					</div>
				</div>

				{/* Filter section */}
				<div className="content-wrapper position-relative">
					<button type="button" className="d-md-none w-100 btn btn-success py-2 mb-2" onClick={handleToggle}><i className="bi bi-funnel"></i> Filter</button>
					{jobstatus ? <div className="notStatus noData">No data to show.</div> :
						<div id="filterWrapper" className={`filter-wrapper d-md-flex flex-column flex-md-row align-items-md-center gap-2 overflow-auto mb-2 p-md-1 ${isAdded ? 'd-flex' : ''}`} style={{ display: 'none' }}>
							<div className="filter-option">
								<Form.Select className="bg-white bg-opacity-50 border-secondary" value={shifttypeoption} onChange={(e) => optionChange(e, 'shift')}>
									<option value="" >Shift Type</option>
									{shifttypeData?.map((shiftname, index) =>
										<option value={shiftname.DISTINCT}>{shiftname.DISTINCT}</option>
									)}
								</Form.Select>
							</div>
							<div className="filter-option">
								<Form.Select className="bg-white bg-opacity-50 border-secondary" value={jobtypeoption} onChange={(e) => optionChange(e, 'jobnature')}>
									<option value="" >Job Nature</option>
									{jobnatureData?.map((jobname, index) =>
										<option value={jobname.DISTINCT}>{jobname.DISTINCT}</option>
									)}
								</Form.Select>
							</div>
							<div className="filter-option">
								<Form.Select className="bg-white bg-opacity-50 border-secondary" value={currentjobstatus} onChange={(e) => optionChange(e, 'currentjobstatus')}>
									<option value="" >Job Status</option>
									{dynamicstatus?.map((jobname, index) =>
										<option value={jobname.key}>{jobname.value}</option>
									)}
								</Form.Select>
							</div>
							{user?.userType != 'MGR' ?
							<div className="filter-option">
								<Form.Select className="bg-white bg-opacity-50 border-secondary" value={selectedReqoption} onChange={(e) => optionChange(e, 'requestedBy')}>
									<option value="" >Requested By</option>
									{reqbyData?.map((reqname, index) =>
										<option value={reqname.requested_by_email}>{reqname.requested_by}</option>
									)}
								</Form.Select>
							</div>
							:''}
							<div className="filter-option">
								<Form.Select className="bg-white bg-opacity-50 border-secondary" value={selectedDeptoption} onChange={(e) => optionChange(e, 'department')}>
									<option value="" >Department</option>
									{deptData?.map((deptname, index) =>
										<option value={deptname.DISTINCT}>{deptname.DISTINCT}</option>
									)}
								</Form.Select>
							</div>
							<div className="filter-option">
								<Form.Select className="bg-white bg-opacity-50 border-secondary" value={selectedEmpoption} onChange={(e) => optionChange(e, 'emptype')}>
									<option value="" >Employment Type</option>
									{empData?.map((emptype, index) =>
										<option value={emptype.DISTINCT}>{emptype.DISTINCT}</option>
									)}
								</Form.Select>
							</div>
							<div className="filter-option">
								<Form.Select className="bg-white bg-opacity-50 border-secondary" value={selectedExpoption} onChange={(e) => optionChange(e, 'exp')}>
									<option value="" >Experience Level</option>
									{expData?.map((explist, index) =>
										<option value={explist.DISTINCT}>{explist.DISTINCT} years</option>
									)}
								</Form.Select>
							</div>
							{user?.userType != 'HR' && user?.userType != 'MGR' && user?.userType != 'SMGR' ?
								<div className="filter-option">
									<Form.Select className="bg-white bg-opacity-50 border-secondary" value={hrtypedata} onChange={(e) => optionChange(e, 'hrtype')}>
										<option value="" >Select HR</option>
										<option value="1">Inhouse HR</option>
										<option value="2">Outsourced HR</option>
									</Form.Select>
								</div> : ''}
						</div>
					}
				</div>

				{/* Info Boxes section */}
				<div className='info-card-sec mb-3'>
					<div className='row g-2 g-md-3'>
						<div className='col-md-4 col-sm-6'>
							<div className='bg-info bg-opacity-25 border-bottom border-4 border-info rounded-3 pointer h-100 p-3'>
								<div className="d-flex align-items-center justify-content-between gap-3">
									<div className='card-details'>
										<div className="label-value fs-3 fw-bold lh-1 mb-1">{setResponsedata?.totalCount}</div>
										<div className="label-title small text-muted lh-sm">Total Recruitment</div>
									</div>
									<div className='card-iconn text-info lh-1'>
										<i className="bi bi-people-fill fs-1"></i>
									</div>
								</div>
							</div>
						</div>
						<div className='col-md-4 col-sm-6'>
							<div className='bg-success bg-opacity-25 border-bottom border-4 border-success rounded-3 pointer h-100 p-3'>
								<div className="d-flex align-items-center justify-content-between gap-3">
									<div className='card-details'>
										<div className="label-value fs-3 fw-bold lh-1 mb-1">{setResponsedata?.openCount}</div>
										<div className="label-title small text-muted lh-sm">Open Recruitment</div>
									</div>
									<div className='card-iconn text-success lh-1'>
										<i className="bi bi-person-fill-up fs-1"></i>
									</div>
								</div>
							</div>
						</div>
						<div className='col-md-4 col-sm-6'>
							<div className='bg-danger bg-opacity-25 border-bottom border-4 border-danger rounded-3 pointer h-100 p-3'>
								<div className="d-flex align-items-center justify-content-between gap-3">
									<div className='card-details'>
										<div className="label-value fs-3 fw-bold lh-1 mb-1">{setResponsedata?.closeCount}</div>
										<div className="label-title small text-muted lh-sm">Closed Recruitment</div>
									</div>
									<div className='card-iconn text-danger lh-1'>
										<i className="bi bi-person-fill-check fs-1"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* Cards Listing Section */}
				<div className="recruitment-dtl-wrapper">
					<div className="bg-white rounded-3 overflow-hidden position-relative pb-4" style={{ minHeight: '60vh' }}>
						<>
							<div className="row g-0">
								<div className="col-md-5 col-xl-4 col-xxl-3">
									{/* {isLoading ? <Loader /> : */}
									<div className="job-card-wrapper border-end">
										<div className="p-2">
											<div className="searchfield-wrapper m-1">
												<input type="search" className="form-control rounded-2" id="search" value={searchVal !== '' && searchVal !== undefined ? `${searchVal}` : ''} onChange={(e) => optionChange(e, 'searchData')} placeholder="Search by Req ID or Title" />
												<img src={searchIcon} alt="Search Icon" className="position-absolute top-50 end-0 translate-middle" />
											</div>
										</div>
										{
											items?.length > 0 ?
												items.map((info, index) => (
													<div className={`job-card pointer p-3 ${index === selectedItem ? 'selected' : ''}`} key={index} onClick={() => handleItemClick(info, index)}>
														<div className="job-title fs-18 text-truncate text-capitalize">{info.jobTitle}</div>
														<div className="job-Id text-truncate text-secondary">(VEREQ{info.id})</div>
														<div className="job-department text-muted text-truncate text-capitalize">{info.department}</div>

														<div className="job-location text-secondary text-truncate text-capitalize">{info.location}</div>
														<div className="job-posted text-truncate text-secondary">{info.time_ago}</div>
													</div>
												)) : <p className="fs-6 fw-medium text-danger text-center p-3 ">No Record Found</p>}
									</div>
									{/* } */}
								</div>
								<div className="col-md-7 col-xl-8 col-xxl-9">
									<div className="job-details-wrapper position-relative">
										{isLoadingdata ?
											<Loader /> :
											itemsview ?
												<div className="info-wrapper p-3 px-xxl-5">
													<div className="job-card-highlighter border border-2 rounded-3 p-3">
														<div className="row">
															<div className="col-xxl-9">
																<div className="info-section">

																	<div className="d-flex flex-wrap align-items-center justify-content-between gap-2 mb-2">
																		<div className="job-title fs-4 fw-bold text-truncate text-capitalize lh-sm mb-1">{itemsview.jobTitle ? itemsview.jobTitle : ''}</div>
																		<div>
																			{itemsview?.status == 'Closed' ? (<span className="badge bg-danger">Closed</span>) : itemsview?.status == 'Hold' ? (<span className="badge bg-warning">Hold</span>) : itemsview?.status == 'Open' ? (<span className="badge bg-success">Open</span>) : ''}
																		</div>

																	</div>
																	<div className="d-flex flex-wrap mb-2 pb-xxl-1">
																		<div className="job-Id add-dot text-capitalize">VEREQ{itemsview?.id}</div>
																		<div className="job-department add-dot text-capitalize">{itemsview.department ? itemsview.department : ''}</div>
																		<div className="job-location add-dot text-capitalize">{itemsview.location ? itemsview.location : ''}</div>
																		<div className="job-posted add-dot">{outerData.time_age ? outerData.time_age : ''}</div>
																		<div className="job-posted text-capitalize">{outerData.applicants ? outerData.applicants : 0} applicants</div>
																	</div>

																	{user?.userType === "HR" ?
																		<div className="hr-profile-wrapper d-flex align-items-center gap-1 mb-2">
																			<div className="hr-profiles d-flex flex-wrap">
																				{myrequistion.map((reqindata, index) => (
																					<>
																						{reqindata.veuser?.hrType == 1 ?
																							<>
																								{reqindata.veuser.profile_image ?
																									<img src={reqindata.veuser.profile_image} alt="HR Profile" className="img-fluid border rounded-circle" style={{ width: '30px', minWidth: '30px', height: '30px' }} /> :
																									<img src={require('../assets/dummy-user.jpeg')} alt="HR Profile" className="img-fluid border rounded-circle" style={{ width: '30px', minWidth: '30px', height: '30px' }} />
																								}
																							</> : ''}
																					</>
																				))}
																			</div>
																			<OverlayTrigger delay={{ hide: 450, show: 300 }} containerPadding={20}
																				overlay={(props) => (
																					<Tooltip {...props}>
																						{countInhousedata != 'No' ?
																							<div className="d-flex flex-column gap-2 overflow-auto m-1" style={{ maxHeight: '200px' }}>
																								{myrequistion.map((reqindatanxt, index) => (
																									<>
																										{reqindatanxt.veuser?.hrType == 1 ?
																											<div className="d-flex align-items-center gap-2">
																												<div className="img-sec">
																													{reqindatanxt.veuser.profile_image ?
																														<img src={reqindatanxt.veuser.profile_image} alt="HR Profile" className="img-fluid border rounded-circle" style={{ width: '30px', minWidth: '30px', height: '30px' }} /> :
																														<img src={require('../assets/dummy-user.jpeg')} alt="HR Profile" className="img-fluid border rounded-circle" style={{ width: '30px', minWidth: '30px', height: '30px' }} />
																													}
																													{/* <img src={require('../assets/dummy-user.jpeg')} alt="HR Profile" className="img-fluid border rounded-circle" /> */}
																												</div>
																												<div className="hr-name text-truncate text-capitalize">{reqindatanxt.veuser.name}</div>
																											</div>
																											: ''}
																									</>
																								))}
																							</div> : "No Inhouse HR is working on this job"}
																					</Tooltip>
																				)}
																				placement="bottom">
																				<div className="total-hr text-primary pointer">{countInhousedata} Inhouse HR working on this job</div>
																				{/* <Button variant="warning">Tooltip Button</Button>  */}
																			</OverlayTrigger>
																			{/* <div className="total-hr text-primary">{itemsview.my_requisitions?.length >=1 ? itemsview.my_requisitions.length:'No'} Inhouse HR working on this job</div> */}
																		</div>
																		:
																		<>
																			<div className="hr-profile-wrapper d-flex align-items-center gap-1 mb-2">
																				<div className="hr-profiles d-flex flex-wrap">
																					{myrequistion.map((reqindata, index) => (
																						<>
																							{reqindata.veuser?.hrType == 1 ?
																								<>
																									{reqindata.veuser.profile_image ?
																										<img src={reqindata.veuser.profile_image} alt="HR Profile" className="img-fluid border rounded-circle" style={{ width: '30px', minWidth: '30px', height: '30px' }} /> :
																										<img src={require('../assets/dummy-user.jpeg')} alt="HR Profile" className="img-fluid border rounded-circle" style={{ width: '30px', minWidth: '30px', height: '30px' }} />
																									}
																								</> : ''}
																						</>
																					))}
																				</div>
																				<OverlayTrigger delay={{ hide: 450, show: 300 }} containerPadding={20}
																					overlay={(props) => (
																						<Tooltip {...props}>
																							{countInhousedata != 'No' ?
																								<div className="d-flex flex-column gap-2 overflow-auto" style={{ maxHeight: '200px' }}>
																									{myrequistion.map((reqindatanxt, index) => (
																										<>
																											{reqindatanxt.veuser?.hrType == 1 ?
																												<div className="d-flex align-items-center gap-2">
																													<div className="img-sec">
																														{reqindatanxt.veuser.profile_image ?
																															<img src={reqindatanxt.veuser.profile_image} alt="HR Profile" className="img-fluid border rounded-circle" style={{ width: '30px', minWidth: '30px', height: '30px' }} /> :
																															<img src={require('../assets/dummy-user.jpeg')} alt="HR Profile" className="img-fluid border rounded-circle" style={{ width: '30px', minWidth: '30px', height: '30px' }} />
																														}

																													</div>
																													<div className="hr-name text-truncate text-capitalize">{reqindatanxt.veuser.name}</div>
																												</div>
																												: ''}
																										</>
																									))}
																								</div> : "No Inhouse HR is working on this job"}
																						</Tooltip>
																					)}
																					placement="bottom">
																					<div className="total-hr text-primary pointer">{countInhousedata} Inhouse HR working on this job</div>
																				</OverlayTrigger>
																			</div>

																			<div className="hr-profile-wrapper d-flex align-items-center gap-1 mb-2">
																				<div className="hr-profiles d-flex flex-wrap">
																					{myrequistion.map((reqoutdata, index) => (
																						<>
																							{reqoutdata.veuser?.hrType == 2 ?
																								<>
																									{reqoutdata.veuser.profile_image ?
																										<img src={reqoutdata.veuser.profile_image} alt="HR Profile" className="img-fluid border rounded-circle" /> :
																										<img src={require('../assets/dummy-user.jpeg')} alt="HR Profile" className="img-fluid border rounded-circle" />
																									}
																								</> : ''}
																						</>
																					))}
																				</div>
																				<OverlayTrigger
																					delay={{ hide: 450, show: 300 }}
																					overlay={(props) => (
																						<Tooltip {...props}>
																							{countOutsourced != 'No' ?
																								<div className="d-flex align-items-center gap-2 overflow-auto" style={{ maxHeight: '200px' }}>
																									{myrequistion.map((reqoutdatanxt, index) => (
																										<>
																											{reqoutdatanxt.veuser?.hrType == 2 ?
																												<div className="d-flex align-items-center gap-2">
																													<div className="img-sec">
																														{reqoutdatanxt.veuser.profile_image ?
																															<img src={reqoutdatanxt.veuser.profile_image} alt="HR Profile" className="img-fluid border rounded-circle" style={{ width: '30px', minWidth: '30px', height: '30px' }} /> :
																															<img src={require('../assets/dummy-user.jpeg')} alt="HR Profile" className="img-fluid border rounded-circle" style={{ width: '30px', minWidth: '30px', height: '30px' }} />
																														}

																													</div>
																													<div className="hr-name text-truncate text-capitalize">{reqoutdatanxt.veuser.name}</div>
																												</div> : ''}
																										</>
																									))}
																								</div> : 'No Outsourced HR is working on this job'}
																						</Tooltip>
																					)}
																					placement="bottom">
																					<div className="total-hr text-primary pointer">{countOutsourced} Outsourced HR working on this job</div>
																				</OverlayTrigger>
																			</div>
																		</>
																	}

																	<div className="info_wrapper d-md-flex gap-3">
																		<div className="title_name text-nowrap text-secondary">Requested By -</div>
																		<div className="value text-capitalize">
																			<span className="req_name me-2">{itemsview.requested_by ? itemsview.requested_by : ''}</span>
																			{/* <span className="req_position me-2">Manager,</span>
                                                                            <span className="req_department">Mobile Application Department</span> */}
																		</div>
																	</div>
																</div>
															</div>
															{!isHR ? 
																<div className="col-xxl-3 mt-2 mt-xxl-0">
																	<div className="action-section d-flex flex-xxl-column flex-wrap justify-content-xxl-end gap-2">
																		<Button type="button" variant="success" className="focus-ring focus-ring-secondary px-3" onClick={() => Myrequisition(itemsview)}>View Details</Button> 
																	</div>
																</div>
																:
																<div className="col-xxl-3 mt-2 mt-xxl-0">
																	<div className="action-section d-flex flex-xxl-column flex-wrap justify-content-xxl-end gap-2">
																		{isAccepted ? <>
																			<Button type="button" variant="danger" className="focus-ring focus-ring-success px-3">Accepted</Button>
																			<Button type="button" variant="success" className="focus-ring focus-ring-secondary px-3" onClick={() => Myrequisition(itemsview)}>View Details</Button>
																			</>
																			: <Button type="button" variant="success" className="focus-ring focus-ring-success px-3" onClick={() => acceptJob(itemsview)}>Accept This Job</Button>
																		}
																		{itemsview?.status != 'Closed' && isAccepted ?
																			<Button type="button" variant="warning" className='focus-ring focus-ring-warning px-4' onClick={(e) => changeStatus(e, itemsview)}>Change Status</Button>
																			: ""}
																		{/* <Button type="button" variant="danger" className='focus-ring focus-ring-danger px-4' >Closed</Button> */}
																	</div>
																</div>
															}
														</div>
													</div>

													<div className="other-brief-info mt-4">
														<div className="row g-3">
															<div className="col-6 col-xl-4">
																<div className="info_wrapper">
																	<div className="title_name text-secondary">Job Title</div>
																	<div className="value text-capitalize">{itemsview.jobTitle ? itemsview.jobTitle : ''}</div>
																</div>
															</div>
															<div className="col-6 col-xl-4">
																<div className="info_wrapper">
																	<div className="title_name text-secondary">Minimum Experience</div>
																	<div className="value text-capitalize">{itemsview.minimumExp ? itemsview.minimumExp : ''} Year</div>
																</div>
															</div>
															<div className="col-6 col-xl-4">
																<div className="info_wrapper">
																	<div className="title_name text-secondary">Location</div>
																	<div className="value text-capitalize">{itemsview.location ? itemsview.location : ''}</div>
																</div>
															</div>
															<div className="col-6 col-xl-4">
																<div className="info_wrapper">
																	<div className="title_name text-secondary">Employment Type</div>
																	<div className="value text-capitalize">{itemsview.employmentType ? itemsview.employmentType : ''}</div>
																</div>
															</div>
															<div className="col-6 col-xl-4">
																<div className="info_wrapper">
																	<div className="title_name text-secondary">Key Skills</div>
																	<div className="value text-capitalize">{itemsview.keySkills ? itemsview.keySkills : ''}</div>
																</div>
															</div>
															<div className="col-6 col-xl-4">
																<div className="info_wrapper">
																	<div className="title_name text-secondary">Primary Role</div>
																	<div className="value text-capitalize">{itemsview.primaryRole ? itemsview.primaryRole : ''}</div>
																</div>
															</div>
															<div className="col-6 col-xl-4">
																<div className="info_wrapper">
																	<div className="title_name text-secondary">Minimum Qualification</div>
																	<div className="value text-capitalize">{itemsview.minimumQualification ? itemsview.minimumQualification : ''}</div>
																</div>
															</div>
															<div className="col-6 col-xl-4">
																<div className="info_wrapper">
																	<div className="title_name text-secondary">Department</div>
																	<div className="value text-capitalize">{itemsview.department ? itemsview.department : ''}</div>
																</div>
															</div>
															<div className="col-6 col-xl-4">
																<div className="info_wrapper">
																	<div className="title_name text-secondary">Approved date</div>
																	<div className="value text-capitalize">{itemsview.approvalDate ? moment(itemsview.approvalDate).format('YYYY-MM-DD') : ''}</div>
																</div>
															</div>
															<div className="col-6 col-xl-4">
																<div className="info_wrapper">
																	<div className="title_name text-secondary">Inhouse HR Working</div>
																	<div className="value text-capitalize"><span>{hrwokingdata ? hrwokingdata : 'No Inhouse HR working'}</span></div>
																</div>
															</div>
															<div className="col-6 col-xl-4">
																<div className="info_wrapper">
																	<div className="title_name text-secondary">Outsourced HR Working</div>
																	{user?.userType == "HR" && user.hrType == '1'?
																	<div className="value text-capitalize"><span>{outsourcedHR ? 'Some Outsourced HR working' : 'No Outsourced HR working'}</span></div>
																	:<div className="value text-capitalize"><span>{outsourcedHR ? outsourcedHR : 'No Outsourced HR working'}</span></div>
																	}
																	 
																	{/* change logic */}
																</div>
															</div>
															
															<div className="col-6 col-xl-4">
																<div className="info_wrapper">
																	<div className="title_name text-secondary">Required By</div>
																	<div className="value text-capitalize">{itemsview.reqiredBy ? itemsview.reqiredBy : ''}</div>
																</div>
															</div>
															{itemsview?.status == 'Closed' ?
															<div className="col-6 col-xl-4">
																<div className="info_wrapper">
																	<div className="title_name text-secondary">Closing Date</div>
																	{/* <div className="value text-capitalize">{itemsview?.closedDate ?  moment(itemsview.closedDate).format('DD/MM/YYYY, hh:mm A') : 'NA'}</div> */}
																	<div className="value text-capitalize">{itemsview?.closedDate ?  moment(itemsview.closedDate).format('YYYY-MM-DD, hh:mm A') : 'NA'}</div>
																</div>
															</div>
															:''}
															<div className="col-12">
																<div className="info_wrapper">
																	<div className="title_name text-secondary">Job Description</div>
																	<div className="value">
																		<div style={{ textAlign: 'justify' }}>
																			{console.log(itemsview)}
																			{/* {itemsview.descriptionHtml ? <HtmlToTextComponent htmlString={itemsview.descriptionHtml} /> : itemsview.jobDescription ? <HtmlToTextComponent htmlString={itemsview.jobDescription} /> : ''} */}
																			{itemsview.descriptionHtml ? <HtmlToTextComponent htmlString={itemsview.descriptionHtml} /> : ''}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div> :
												<div className="no-data-wrapper bg-white rounded px-3 py-5 mt-2">
													<div className="text-center mx-auto" style={{ maxWidth: '350px' }}>
														<img src={require('../assets/no-data.jpg')} alt="Candidate" className="img-fluid" />
														<div className='fs-4 fw-semibold text-danger mt-3'>Sorry no record Found !</div>
														<div className='small text-muted'>Whoops... this information is not available for a moment</div>
													</div>
												</div>
										}
									</div>
								</div>
							</div>
							{items?.length >= 1 ?
								<Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
								: ''}
						</>
					</div>
				</div>
			</div>

			{/* Requisition Status modal */}
			<Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg" centered scrollable contentClassName="border-0">
				<Modal.Header closeVariant="white" className="bg-primary text-light px-md-4">
					<Modal.Title className="fs-6 fw-medium" closeVariant="btn-close-white">Requisition Status</Modal.Title>
				</Modal.Header>
				<Modal.Body className="px-md-4 py-4">
					<form onSubmit={handleSubmit}>
						<div className="requisitionStatus">
							<label>Select Status</label>
							<select className="form-select bg-light py-2" name="status" onChange={getOption} >
								<option hidden>{itemsview?.status == 'Closed' || itemsview?.status == 'Open' || itemsview?.status == 'Hold' ? itemsview?.status : 'Select Job Status'}</option>
								{dynamicstatus?.map((info, index) =>
									// <option value={info.key}>{info.value}</option>
									<>
										{itemsview?.status != info.key ?
											<option value={info.key}>{info.value}</option>
											: ''
										}
									</>
								)}
							</select>
							{/* comment */}
							<div className="my-3">
								<label>Comments</label>
								<textarea className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={getOption} name="comment" value={formData.comment}></textarea>
							</div>
							{/* comment */}
						</div>
						<div className="d-flex py-3 px-2 justify-content-center">
							<Button type="button" variant="secondary" className='focus-ring focus-ring-secondary px-4' onClick={() => closeModal('outerModal')}>Cancel</Button>
							<Button type="button" variant="danger" className='focus-ring focus-ring-danger px-4 mx-2' onClick={() => submitValue("passValue", statusValue, reqId, itemsview?.status)}>Submit</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* Requisition Status modal */}

			{/* Warning Modal */}
			<Modal show={innershow} onHide={handleClose} backdrop="static" keyboard={false} size="lg" centered scrollable contentClassName="border-0">
				<Modal.Header closeVariant="white" className="bg-primary text-light px-md-4">
					<Modal.Title className="fs-6 fw-medium" closeVariant="btn-close-white">Confirmation</Modal.Title>
				</Modal.Header>
				<Modal.Body className="px-md-4 py-4">
					<p className="text-center fs-18">Are you sure you want to <span className="text-lowercase">{statusValue}</span> this requisition?</p>
					<div className="d-flex py-3 px-2 justify-content-center">
						<Button type="button" variant="secondary" className='focus-ring focus-ring-secondary px-4' onClick={() => closeModal('innerModal')}>Cancel</Button>
						<Button type="button" variant="danger" className='focus-ring focus-ring-danger px-4 mx-2' onClick={() => submitValue("finalSubmit", statusValue, reqId, itemsview?.status)}>Submit</Button>
					</div>
				</Modal.Body>
			</Modal>
			{/* Warning Modal */}
			<ToastContainer />
		</div>
	)
}