import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './CandidateDetail.scss';
import { Button } from 'react-bootstrap';
import CryptoJS from 'crypto-js';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { UserAuth } from '../../context/AuthContext';
import axios from 'axios';
import RequisitionPagination from '../../components/RequisitionPagination';
import Statuscard from '../../cards/StatusCard';
import { Loader } from '../../components/loader';
import Avatar from '../../assets/Avatar.png';
// card
import InterviewCard from './InterviewCard';
import InterviewScheduled from './InterviewScheduled';
import CandidateAdded from './CandidateAdded';
import Requisitionhistory from './RequisitionHistory';
import InterviewHistory from './InterviewHistory';
import ApplicationHistory from './ApplicationHistory';
import RegistrationCard from './RegistrationCard';

export const CandidateDetail = () => {
    const navigate = useNavigate();
    const { user, userInfoLoaded, currentUserVal } = UserAuth();
    const [candidateinfo, setcandidateinfo] = useState('')
    const [apphistory, setapphistory] = useState([])
    const [inthistory, setinthistory] = useState([])
    const [requisitionhistory, setrequisitionhistory] = useState([])
    const [cards, setMemberCards] = useState([]);
    // For candidate details main tabs
    const [detailsTab, setDetailsTab] = useState('Details');
    const [loading, setLoading] = useState(false)
    // pagination
    const [currentPageAppHistory, setCurrentPageAppHistory] = useState(1);
    const [apphistorytotalPages, setApphistoryTotalPages] = useState(0);
    const [currentPageInterviewHistory, setCurrentPageInterviewHistory] = useState(1);
    const [inthistorytotalPages, setInthistoryTotalPages] = useState(0);
    const [currentPageRequisitionHistory, setCurrentPageRequisitionHistory] = useState(1);
    const [reqhistorytotalPages, setReqhistoryTotalPages] = useState(0);
    const [currentPageActivityHistory, setCurrentPageActivityHistory] = useState(1);
    const [activitytotalPages, setActivityTotalPages] = useState(0);
    const itemsPerPage = 10;
    const [collectdata, setalldata] = useState('')
    const [showpagination, setpagination] = useState(true)
    // const handlePageChange = (pageNumber) => {
    //     setCurrentPage(pageNumber);
    // };

    const handlePageChange = (page, tabName) => {
        if (tabName === 'ApplicationHistory') {
            setCurrentPageAppHistory(page);
        } else if (tabName === 'InterviewHistory') {
            setCurrentPageInterviewHistory(page);
        } else if (tabName === 'RequisitionHistory') {
            setCurrentPageRequisitionHistory(page);
        } else if (tabName === "ActivityHistory") {
            setCurrentPageActivityHistory(page);
        }
    };
    // pagination

    // For more details info tabs
    const [moreDetailsTab, setMoreDetailsTab] = useState('PersonalInformation');
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const encryptedData = searchParams.get('query');
        if (encryptedData) {
            // Decrypt the encrypted data
            const bytes = CryptoJS.AES.decrypt(encryptedData, 'secret_key');
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            console.log(decryptedData)
            console.log("decryptedData!!")
            setalldata(decryptedData)
            setcandidateinfo(decryptedData)
            applicationhistorydata(decryptedData)
            interviewhistory(decryptedData)
            reqhistory(decryptedData)
            candidateactivity(decryptedData)
        }
    }, [currentPageAppHistory, currentPageInterviewHistory, currentPageRequisitionHistory, currentPageActivityHistory])

    // no data message
    const nodata = (
        <div className="no-data-wrapper bg-white rounded px-3 py-5 mt-2">
            <div className="text-center mx-auto" style={{ maxWidth: '350px' }}>
                <img src={require('../../assets/no-data.jpg')} alt="Candidate" className="img-fluid" />
                <div className='fs-4 fw-semibold text-danger mt-3'>Sorry no record found!</div>
                <div className='small text-muted d-none'>No candidates registered on the selected date</div>
            </div>
        </div>
    );
    // no data message

    const timeFormat = (dateData) => {
        const originalDateString = dateData;
        const formattedDate = moment(originalDateString).format('YYYY-MM-DD');
        return formattedDate
    }

    // application history
    const applicationhistorydata = async (getData) => {
        setLoading(true)
        const refreshedToken = await currentUserVal?.getIdToken(true);
        var datasend = {
            candidateEmail: getData?.email,
            page: currentPageAppHistory,
            limit: itemsPerPage,
            pageSize: itemsPerPage
        };
        if (user?.userType == "MGR" || user?.userType == "SMGR") {
            datasend.userType = user?.userType;
            datasend.managerEmail = getData?.email;
        }
        var headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'application/json'
        };
        axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/candidates/get-schdule-candidate-list-new`, datasend, { headers })
            .then(function (response) {
                setapphistory(response?.data.list)
                setApphistoryTotalPages(response?.data.totalPage)
                setLoading(false)
            })
            .catch(function (error) {
                setLoading(false)
            });
    }
    // application history

    // candidate activity
    const candidateactivity = async (candidateEmail) => {
        try {
            const refreshedToken = await currentUserVal?.getIdToken(true);
            const postData = {
                "email": candidateEmail?.email,
                // "userType": "HR",
                "page": currentPageActivityHistory,
                "limit": itemsPerPage,
                "pageSize": itemsPerPage
            };
            var headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/getRequisitionActivities`, postData, { headers })
            console.log(response.data.memberCards)
            console.log("response.data.memberCards")
            setMemberCards(response.data)
            const countPage = response.data.pagination
            setActivityTotalPages(countPage.totalPage)
        }
        catch (error) {
            console.log("Error: ", error)
        }
    }

    let memberCard;
    if (cards?.memberCards && cards?.memberCards.length > 0) {
        memberCard = cards.memberCards.map((item, index) => {
            if (item.card_type === "Profile Created") {
                return (
                    <>
                        <RegistrationCard candidateValues={item.candidateInfo} userotherinfo={item.user_other_info} senddata={collectdata} />
                    </>
                )
            }
            else if (item.card_type === "Interview Scheduled") {
                return (
                    <>
                        <InterviewScheduled scheduleData={item.interview_schedule} senddata={collectdata} inttype={"schedule"} user={user} />
                        <CandidateAdded profileData={item.interview_schedule} candidateData={item} senddata={collectdata} user={user} />
                    </>
                )
            }
            else if (item.card_type === "Interview rescheduled") {
                return (
                    <>
                        <InterviewScheduled scheduleData={item.interview_schedule} senddata={collectdata} inttype={"reschedule"} />
                    </>
                )
            }
            else if (item.card_type === "Interview Started") {
                return (
                    <>
                        <InterviewCard startedData={item.nminterviewdatum} senddata={collectdata} />
                    </>
                )
            } else if (item.card_type === "Status") {
                return (
                    <>
                        <Statuscard statusdata={item.requisition_status} candidateValues={item.candidateInfo} />
                    </>
                )
            }
        })
    } else {
        memberCard = nodata
    }
    // candidate activity

    // Interview History
    const interviewhistory = async (candidateEmail) => {
        setLoading(true)
        try {
            const refreshedToken = await currentUserVal?.getIdToken(true);
            var data = JSON.stringify({
                "candidateEmail": candidateEmail?.email,
                "page": currentPageInterviewHistory,
                "limit": itemsPerPage,
                "pageSize": itemsPerPage
                // "candidateEmail": "vinodjaiswal87@gmail.com"
            });
            var headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.post(`${process.env.REACT_APP_TESTSERVER}get-candidate-Allinterview`, data, { headers });
            let testDatares = response.data;
            let dataRes = testDatares.interviews?.length > 0 ? testDatares.interviews : [];
            console.log("Test All Data List");
            console.log(dataRes);
            setinthistory(dataRes);
            setInthistoryTotalPages(testDatares.totalPages)
            setLoading(false)
        } catch (error) {
            console.log("Error: ", error)
            setLoading(false)
        }
    }
    // Interview History

    // Requisition History
    const reqhistory = async (candidateEmail) => {
        setLoading(true)
        try {
            const refreshedToken = await currentUserVal?.getIdToken(true);
            var data = JSON.stringify({
                "requisitionId": candidateEmail?.requisition_id,
                "email": candidateEmail?.email,
                "page": currentPageRequisitionHistory,
                "limit": itemsPerPage,
                "pageSize": itemsPerPage
            });
            var headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/getRequisitionHistory`, data, { headers });
            let testDatares = response.data;
            setrequisitionhistory(testDatares.data);
            setReqhistoryTotalPages(testDatares.pagination.totalPage)
            setLoading(false)
        } catch (error) {
            console.log("Error: ", error)
            setLoading(false)
        }
    }
    // Requisition History

    // ViewDetails
    const CandidateDetails = (info, btntype) => {
        let requisitionId = info?.request_id;
        let sendInfo = { "requisitionId": requisitionId, "email": info?.email }
        if (btntype == 'viewDetail') {
            const encryptedQuery = CryptoJS.AES.encrypt(JSON.stringify(sendInfo), 'secret_key').toString();
            navigate(`/hr-candidate-details?query=${encodeURIComponent(encryptedQuery)}`);
        }
    }
    // ViewDetails

    const getUserStatus = (is_registered, is_registration_completed, is_interviewGiven) => {
        // if (is_interviewGiven === 1) {
        //     return "Interview Conducted";
        // } 
        if (is_registered === 1 && is_registration_completed === 1) {
            return "Registration Completed";
        } else if (is_registered === 1 && is_registration_completed === 0) {
            return "Partial Registration";
        } else if (is_registered === 0) {
            return "Not Registered";
        }
    };

    return (
        <div className="CandidateDetail-page py-3">
            <div className="container-fluid">
                <div class="fs-4 fw-semibold lh-sm mb-3">Candidate Details</div>
                <div className="tabs-wrapper">
                    <Tabs id="controlled-tab-example" activeKey={detailsTab} onSelect={(k) => setDetailsTab(k)} className="bg-white rounded-top-3 mb-3">
                        {/* <Tab eventKey="jobDetail" title={<span className="text-capitalize"><i className="bi bi-briefcase me-1"></i> Job Detail</span>}> */}
                        <Tab eventKey="Details" title="Details">
                            <div className='tab-content-wrapper'>
                                <div className="candidate-info-card bg-white fs-14 d-flex flex-wrap align-items-center justify-content-between gap-3 rounded-3 p-3 mb-2">
                                    <div className="d-flex align-items-center row-gap-2 column-gap-3 text-truncate">
                                        <img src={candidateinfo?.user_photo != "" && candidateinfo?.user_photo != null ? candidateinfo?.user_photo : Avatar} alt="Recruiter" className='img-fluid rounded-circle' style={{ height: '75px', width: '75px' }} />
                                        <div className="cand-info text-capitalize text-truncate">
                                            <div className="candi-name fs-18 fw-semibold text-capitalize text-truncate">{candidateinfo?.user_name}</div>
                                            <div className="candi-company text-lowercase text-truncate">{candidateinfo?.email}</div>
                                            <div className="candidate-mob text-capitalize">{candidateinfo?.contact_no}</div>
                                        </div>
                                    </div>
                                    <div className="btn-wrapper">
                                        <Button variant={`${candidateinfo?.is_registered == 1 && candidateinfo?.is_registration_completed == 1 ? 'success' : 'danger'}`} className='btn-custom border-0 rounded-pill px-5' >{getUserStatus(candidateinfo?.is_registered, candidateinfo?.is_registration_completed, 'val')}</Button>
                                    </div>
                                </div>

                                <div className="more-details-section">
                                    <div className="tabs-wrapper bg-white rounded-3 overflow-hidden">
                                        <Tabs id="moreDetailsTabs" activeKey={moreDetailsTab} onSelect={(k) => setMoreDetailsTab(k)} className="">
                                            <Tab eventKey="PersonalInformation" title="Personal Information" className='bg-white fs-14 p-3'>
                                                <div className='tab-content-wrapper'>
                                                    <div className="row g-3 g-xl-2">
                                                        <div className="col-12 col-md-4 item-wrapper d-flex flex-column gap-2">
                                                            <div className="info-box">
                                                                <div className="info-label text-clr1 fs-12">Current Company</div>
                                                                <div className="cand-company text-capitalize">{candidateinfo?.current_company}</div>
                                                            </div>
                                                            <div className="info-box">
                                                                <div className="info-label text-clr1 fs-12">Job Status</div>
                                                                <div className="text-capitalize">{candidateinfo?.job_status}</div>
                                                            </div>
                                                            <div className="info-box">
                                                                <div className="info-label text-clr1 fs-12">Job Role Awareness</div>
                                                                <div className="text-capitalize">{candidateinfo?.is_job_role_aware}</div>
                                                            </div>
                                                            <div className="info-box">
                                                                <div className="info-label text-clr1 fs-12">Notice Period</div>
                                                                <div className="text-capitalize">{candidateinfo?.notice_period}</div>
                                                            </div>
                                                            <div className="info-box">
                                                                <div className="info-label text-clr1 fs-12">Reason for Job Change</div>
                                                                <div className="text-capitalize">{candidateinfo?.reason_job_change}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-4 item-wrapper d-flex flex-column gap-2">
                                                            <div className="info-box">
                                                                <div className="info-label text-clr1 fs-12">Current CTC</div>
                                                                <div className="text-capitalize">{candidateinfo?.current_ctc}</div>
                                                            </div>
                                                            <div className="info-box">
                                                                <div className="info-label text-clr1 fs-12">Expected CTC</div>
                                                                <div className="text-capitalize">{candidateinfo?.expected_ctc}</div>
                                                            </div>
                                                            <div className="info-box">
                                                                <div className="info-label text-clr1 fs-12">Salary Negotiation</div>
                                                                <div className="text-capitalize">{candidateinfo?.is_salary_negotiation}</div>
                                                            </div>
                                                            <div className="info-box">
                                                                <div className="info-label text-clr1 fs-12">Possible of Notice BuyOut</div>
                                                                <div className="text-capitalize">{candidateinfo?.can_notice_buyout}</div>
                                                            </div>
                                                            <div className="info-box">
                                                                <div className="info-label text-clr1 fs-12">Location</div>
                                                                <div className="text-capitalize">{candidateinfo?.can_notice_buyout}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-4 item-wrapper d-flex flex-column gap-2">
                                                            <div className="info-box">
                                                                <div className="info-label text-clr1 fs-12">Comm. Skill Rating</div>
                                                                <div className="text-capitalize">{candidateinfo?.comm_skill_rating}/5</div>
                                                            </div>
                                                            <div className="info-box">
                                                                <div className="info-label text-clr1 fs-12">Cultural Fit Rating</div>
                                                                <div className="text-capitalize">{candidateinfo?.cultural_fit_rating}/5</div>
                                                            </div>
                                                            <div className="info-box">
                                                                <div className="info-label text-clr1 fs-12">Reason for Cultural fit</div>
                                                                <div className="text-capitalize">{candidateinfo?.cultural_fit}</div>
                                                            </div>
                                                            <div className="info-box">
                                                                <div className="info-label text-clr1 fs-12">Interview Medium</div>
                                                                <div className="text-capitalize">{candidateinfo?.interview_medium}</div>
                                                            </div>
                                                            <div className="info-box">
                                                                <div className="info-label text-clr1 fs-12">Interview Date & Time</div>
                                                                <div className="regis-date text-capitalize">{candidateinfo?.interview_date ? timeFormat(candidateinfo?.interview_date) : ""}, {candidateinfo?.interview_time}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-4 item-wrapper d-flex flex-column gap-2">
                                                            <div className="info-box">
                                                                <Button type="button" variant="success" class="focus-ring focus-ring-success px-3 btn btn-success" onClick={() => CandidateDetails(candidateinfo, 'viewDetail')}>View Details</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="ApplicationHistory" title="Application History" className='bg-white fs-14 p-2 px-2 px-md-3 py-3'>
                                                <div className='tab-content-wrapper position-relative'>
                                                    {
                                                        apphistory?.length >= 1 ?
                                                            <>
                                                                {loading == true ? <Loader /> : ""}
                                                                <ApplicationHistory applicationsData={apphistory} user={user} />
                                                                <div className="pagination-wrapper text-center mt-3">
                                                                    {apphistory?.length >= 1 ?
                                                                        <RequisitionPagination currentPage={currentPageAppHistory} totalPages={apphistorytotalPages} onPageChange={(page) => handlePageChange(page, 'ApplicationHistory')} />
                                                                        : ''}
                                                                </div>
                                                            </>
                                                            : (nodata)
                                                    }
                                                    {/* End: Pagination code */}
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="Activity" title="Activity">
                            <div className='tab-content-wrapper position-relative'>
                                <div className="candidate-list d-flex flex-column gap-3">
                                    {loading == true ? <Loader /> : ""}
                                    {memberCard}
                                    {activitytotalPages >= 1 ?
                                        <RequisitionPagination currentPage={currentPageActivityHistory} totalPages={activitytotalPages} onPageChange={(page) => handlePageChange(page, 'ActivityHistory')} />
                                        : ""}
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="InterviewHistory" title="Interview History">
                            <div className='tab-content-wrapper position-relative'>
                                <div className="candidate-list d-flex flex-column gap-3">
                                    {
                                        inthistory?.length >= 1 ?
                                            <>
                                                {loading == true ? <Loader /> : ""}
                                                <InterviewHistory interviewData={inthistory} user={user} />
                                                {inthistory?.length >= 1 ?
                                                    <RequisitionPagination currentPage={currentPageInterviewHistory} totalPages={inthistorytotalPages} onPageChange={(page) => handlePageChange(page, 'InterviewHistory')} />
                                                    : ''}
                                            </>
                                            :
                                            (nodata)
                                    }
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="RequisitionHistory" title="Requisition History">
                            {
                                requisitionhistory?.length >= 1 ?
                                    <>
                                        {loading == true ? <Loader /> : ""}
                                        <Requisitionhistory reqhistorydata={requisitionhistory} user={user} />
                                        {requisitionhistory?.length >= 1 ?
                                            <RequisitionPagination currentPage={currentPageRequisitionHistory} totalPages={reqhistorytotalPages} onPageChange={(page) => handlePageChange(page, 'RequisitionHistory')} />
                                            : ''}
                                    </>
                                    :
                                    (nodata)
                            }
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div >
    )
}
