import React, { useState, useEffect } from 'react';
import { useNavigate, Link, Navigate } from 'react-router-dom';
import searchIcon from '../../assets/search.svg'
import { getAuth } from '../../firebaseConfig';
import { UserAuth } from '../../context/AuthContext';
import axios from 'axios';
import { Loader } from '../../components/loader';
import ExportToExcel from '../../components/ExportToExcel';
import { useMyContext } from '../../context/MyContext';
import CandidateStatus from './CandidateStatus';
import { useNavigationContext } from "../../context/NavContext";
// pagination
import Pagination from '../../components/Pagination';
// pagination
export const CandidateRegistrationStatus = () => {
    const auth = getAuth();
    const navigate = useNavigate();
    const { user, userInfoLoaded, currentUserVal } = UserAuth();
    const { activepage, handlePageClick } = useNavigationContext()
    const { values, handleChange } = useMyContext()
    const [isLoading, setIsLoading] = useState(false);
    const [registeredCandidate, setRegisteredCandidate] = useState([])
    const [isActive, setIsActive] = useState(false);
    const [startDate, setstartDate] = useState('')
    const [endDate, setendDate] = useState('')
    const [regStatus, setregStatus] = useState('')
    const [searchVal, setsearchVal] = useState('')
    const [getworkStatus, setgetworkStatus] = useState('')
    const [getHR, setgetHR] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [gettotalPages, setTotalPages] = useState(1)
    const [gettotalHR, setTotalHR] = useState([])
    const [getHRname, setHRname] = useState('')
    const itemsPerPage = 10;
    const [error, setError] = useState(null);

    // Handle job filter in mobile device
    const [isAdded, setIsAdded] = useState(false);
    const handleToggle = () => {
        setIsAdded(!isAdded);
    };

    const getRegisteredData = async (getData) => {
        setIsLoading(true);
        const sendCurrentpage = getData?.currentPagenumber != '' && getData?.currentPagenumber != undefined ? getData?.currentPagenumber : currentPage
        const refreshedToken = await currentUserVal?.getIdToken(true);
        getData.page = currentPage;
        getData.pageSize = itemsPerPage;
        if (user?.userType == 'HR') {
            getData.eMailId = user?.email
        }
        if (user?.userType == "MGR") {
            getData.userType = user?.userType;
            getData.managerEmail = user?.email;
            // getData.eMailId = user?.email; 
        }
        var data = getData;
        var headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'application/json'
        };
        axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/candidates/get-list?page=${sendCurrentpage}&pageSize=${itemsPerPage}`, data, { headers })
            .then(function (response) {
                setTotalPages('')
                setRegisteredCandidate(response.data?.interviews);
                console.log(response.data?.interviews)
                console.log("response.data?.interviews")
                setTotalPages(response.data?.totalPages)
                setIsLoading(false);
            })
            .catch(function (error) {
                setIsLoading(false);
            });
    }

    // getHRList
    const getHRList = async () => {
        const refreshedToken = await currentUserVal?.getIdToken(true);
        var datasend = {
            dataset: "dataset"
        };
        var headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'application/json'
        };
        axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/candidates/get-hr-list`, datasend, { headers })
            .then(function (response) {
                let HRalldata = response?.data.hrList
                HRalldata.sort((a, b) => a.name.localeCompare(b.name));
                setTotalHR(HRalldata)
                // setTotalHR(response?.data.hrList)
                setIsLoading(false);
            })
            .catch(function (error) {
                setIsLoading(false);
            });
    }
    // getHRList

    // filter Section
    const openFilter = (event) => {
        setIsActive(current => !current);
    };
    const workingStatus = (e) => {
        setgetworkStatus(e.target.value)
        handleChange('candWorkvalue', e.target.value)
        const workstatusvalue = e.target.value
        const sendData = {
            "searchString": searchVal ? searchVal : '',
            "startDate": startDate ? startDate : '',
            "endDate": endDate ? endDate : '',
            "currentJobStatus": workstatusvalue ? workstatusvalue : '',
            "hrType": getHR ? getHR : '',
            "profileStatus": regStatus ? regStatus : '',
            "eMailId": getHRname ? getHRname : ''
        };
        getRegisteredData(sendData)
        setCurrentPage(1)
    }
    const funcGetStatus = (e) => {
        setregStatus(e.target.value)
        handleChange('candRegvalue', e.target.value)
        const getStatus = e.target.value
        const sendData = {
            "searchString": searchVal ? searchVal : '',
            "startDate": startDate ? startDate : '',
            "endDate": endDate ? endDate : '',
            "currentJobStatus": getworkStatus ? getworkStatus : '',
            "hrType": getHR ? getHR : '',
            "profileStatus": getStatus ? getStatus : '',
            "eMailId": getHRname ? getHRname : ''
        };
        getRegisteredData(sendData)
        setCurrentPage(1)
    }
    const searchFunction = (e) => {
        const getSearchvalue = e.target.value
        setsearchVal(e.target.value)
        var sendData = ''
        sendData = {
            "searchString": getSearchvalue ? getSearchvalue : '',
            // "startDate": startDate?startDate:'',
            // "endDate": endDate?endDate:'',
            // "currentJobStatus": getworkStatus?getworkStatus:'',
            // "hrType": getHR?getHR:'',
            // "profileStatus":regStatus?regStatus:''
        };
        getRegisteredData(sendData)
    }
    const handleStartDateChange = (e) => {
        setstartDate(e.target.value)
    }
    const handleEndDateChange = (e) => {
        setendDate(e.target.value)
    }
    const handleApplyClick = () => {
        if (startDate != '' && endDate != '') {
            const sendData = {
                "searchString": searchVal ? searchVal : '',
                "startDate": startDate ? startDate : '',
                "endDate": endDate ? endDate : '',
                "currentJobStatus": getworkStatus ? getworkStatus : '',
                "hrType": getHR ? getHR : '',
                "profileStatus": regStatus ? regStatus : '',
                "eMailId": getHRname ? getHRname : ''
            };
            getRegisteredData(sendData)
            setCurrentPage(1)
        } else {
            setError("Please select from and to Date.");
            return;
        }
    }
    const hrtype = (e) => {
        setgetHR(e.target.value)
        handleChange('canHRvalue', e.target.value)
        const getHRtype = e.target.value
        const sendData = {
            "searchString": searchVal ? searchVal : '',
            "startDate": startDate ? startDate : '',
            "endDate": endDate ? endDate : '',
            "currentJobStatus": getworkStatus ? getworkStatus : '',
            "hrType": getHRtype ? getHRtype : '',
            "profileStatus": regStatus ? regStatus : '',
            "eMailId": getHRname ? getHRname : ''
        };
        getRegisteredData(sendData)
        setCurrentPage(1)
    }
    const hrdata = (e) => {
        setHRname(e.target.value)
        handleChange('canHRemail', e.target.value)
        const getHRemail = e.target.value
        const sendData = {
            "searchString": searchVal ? searchVal : '',
            "startDate": startDate ? startDate : '',
            "endDate": endDate ? endDate : '',
            "currentJobStatus": getworkStatus ? getworkStatus : '',
            "hrType": getHR ? getHR : '',
            "profileStatus": regStatus ? regStatus : '',
            "eMailId": getHRemail ? getHRemail : ''
        };
        getRegisteredData(sendData)
        setCurrentPage(1)
    }
    // filter Section

    useEffect(() => {
        setregStatus(values?.candRegvalue ? values?.candRegvalue : '')
        setgetHR(values?.canHRvalue ? values?.canHRvalue : '')
        setgetworkStatus(values?.candWorkvalue ? values?.candWorkvalue : '')
        setHRname(values?.canHRemail ? values?.canHRemail : '')
        const sendData = {
            "searchString": searchVal ? searchVal : '',
            "startDate": startDate ? startDate : '',
            "endDate": endDate ? endDate : '',
            "currentJobStatus": values?.candWorkvalue ? values?.candWorkvalue : '',
            "hrType": values?.canHRvalue ? values?.canHRvalue : '',
            "profileStatus": values?.candRegvalue ? values?.candRegvalue : '',
            "eMailId": values?.canHRemail ? values?.canHRemail : ''
        };
        getRegisteredData(sendData)
        getHRList()
    }, [currentPage, currentUserVal])

    // buttonClick
    const handleClick = (type) => {
        setIsLoading(true);
        var currentPagenum
        var totalpagenum
        if (type === 'prev' && currentPage > 1) {
            setCurrentPage(currentPage - 1);
            setTotalPages(gettotalPages)
            currentPagenum = currentPage - 1
        } else if (type === 'next' && currentPage < gettotalPages) {
            setCurrentPage(currentPage + 1)
            setTotalPages(gettotalPages)
            currentPagenum = currentPage + 1
        }
        const sendData = {
            "searchString": searchVal ? searchVal : '',
            "startDate": startDate ? startDate : '',
            "endDate": endDate ? endDate : '',
            "currentJobStatus": getworkStatus ? getworkStatus : '',
            "hrType": getHR ? getHR : '',
            "profileStatus": regStatus ? regStatus : '',
            "currentPagenumber": currentPagenum,
            "eMailId": getHRname ? getHRname : ''
        };
        getRegisteredData(sendData)
        setIsLoading(false);
    }
    // buttonClick

    // pagination
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        // Perform any action related to page change, e.g., fetching new data
    };
    // pagination

    return (
        <div className="cand-regis-status py-3">
            <div className="container-fluid">
                <div className='filter-sec'>
                    <div className="d-flex align-items-center justify-content-between gap-2 mb-2 mb-md-1">
                        <div className="filter-label fw-medium pointer" onClick={openFilter}><i className="bi bi-filter-circle-fill" title='Click to filter'></i> Filter By -</div>
                        {registeredCandidate?.length > 0 ?
                            <ExportToExcel data={registeredCandidate} filename="candidate_data" /> :
                            null
                        }
                    </div>
                    <div className="content-wrapper position-relative mb-md-1">
                        <button type="button" className="d-md-none w-100 btn btn-success py-2 mb-2" onClick={handleToggle}><i className="bi bi-funnel"></i> Filter</button>
                        <div id="filterWrapper" className={`filter-wrapper d-md-flex flex-column flex-md-row align-items-md-center gap-1 gap-md-2 overflow-auto p-md-1 ${isAdded ? 'd-flex' : ''}`} style={{ display: 'none' }}>
                            <div className="filter-option searchfield-wrapper">
                                <input type="search" className="form-control rounded-2" id="search" placeholder="Search Here..." value={searchVal} autoComplete='off' onChange={searchFunction} />
                                <img src={searchIcon} alt="Search Icon" className="position-absolute top-50 end-0 translate-middle" />
                            </div>
                            <div className="filter-option">
                                <select value={getworkStatus} onChange={workingStatus} className="form-select" autoComplete='off' required>
                                    <option value="">Working Status</option>
                                    <option value="In Job">In Job</option>
                                    <option value="On Notice Period">On Notice Period</option>
                                    <option value="No Where Working">No Where Working</option>
                                    <option value="Fresher">Fresher</option>
                                </select>
                            </div>
                            <div className="filter-option">
                                <select value={regStatus} onChange={funcGetStatus} className="form-select" autoComplete='off' required>
                                    <option value="">Registration Status</option>
                                    <option value="complete">Completed</option>
                                    <option value="incomplete">Incomplete</option>
                                </select>
                            </div>
                            {user?.userType != 'HR' && user?.userType != 'MGR' && user?.userType != 'SMGR' ?
                                <div className="filter-option">
                                    <select value={getHR} onChange={hrtype} className="form-select" autoComplete='off' required>
                                        <option value="">HR Type</option>
                                        <option value="1">Inhouse HR</option>
                                        <option value="2">Outsourced HR</option>
                                    </select>
                                </div> : ''}
                            {user?.userType != 'HR' && user?.userType != 'MGR' && user?.userType != 'SMGR' ?
                                <div className="filter-option">
                                    <select value={getHRname} onChange={hrdata} className="form-select" autoComplete='off' required>
                                        <option value="">Select HR</option>
                                        {gettotalHR.map((info, id) => (
                                            <option value={info?.email} className="text-capitalize">{info?.name}</option>
                                        ))}
                                    </select>
                                </div> : ''}
                            {/* gettotalHR */}
                            <div className="date-filter d-flex align-items-center gap-1 bg-white border rounded-2 px-3 position-relative">
                                <input type="date" className="form-control border-0 ps-0" id="startDate" value={startDate} onChange={handleStartDateChange} required />
                                <input type="date" className="form-control border-0 ps-0" id="endDate" value={endDate} onChange={handleEndDateChange} required />
                                <button type='button' className='btn btn-sm btn-primary px-3 lh-1' onClick={() => handleApplyClick()}>Apply</button>
                                {error && <p className="text-danger errMsg">{error}</p>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="cards-listing position-relative">
                    {isLoading ? (
                        <Loader />
                    ) : ''}

                    {/* CandidateStatus component */}
                    <CandidateStatus CandidateData={registeredCandidate} />
                    {/* CandidateStatus component */}

                    {/* No data available */}
                    {registeredCandidate?.length == 0 ? (
                        <div className="no-data-wrapper bg-white rounded px-3 py-5 mt-2">
                            <div className="text-center mx-auto" style={{ maxWidth: '350px' }}>
                                <img src={require('../../assets/no-data.jpg')} alt="Candidate" className="img-fluid" />
                                <div className='fs-4 fw-semibold text-danger mt-3'>Sorry no record Found !</div>
                                <div className='small text-muted'>Whoops... this information is not available for a moment</div>
                            </div>
                        </div>) : null
                    }
                    {/* No data available */}

                    {/* Start: Pagination code */}
                    <div className="pagination-wrapper text-center mt-3">
                        {registeredCandidate?.length >= 1 ?
                            // <div className='bg-white d-inline-flex align-items-center border rounded overflow-hidden'>
                            //     <button type="button" className="btn btn-secondary border-0 rounded-0 py-2" onClick={() => handleClick('prev')} disabled={currentPage === 1}>Prev</button>
                            //     <span className='px-3'>Page <span className="fw-bold text-primary">{currentPage}</span> of <span className="fw-bold text-primary">{gettotalPages}</span></span>
                            //     <button type="button" className="btn btn-primary border-0 rounded-0 py-2" onClick={() => handleClick('next')} disabled={currentPage === gettotalPages}>Next</button>
                            // </div>
                            <Pagination currentPage={currentPage} totalPages={gettotalPages} onPageChange={handlePageChange} />
                            : ''}
                    </div>
                    {/* End: Pagination code */}

                </div>
            </div>
        </div>
    )
}
